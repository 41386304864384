export default [
	{"en_name": "Afghanistan", "zh_name": "阿富汗", "dh": "AF", "code": 93},
	{"en_name": "Albania", "zh_name": "阿尔巴尼亚", "dh": "AL", "code": 355},
	{"en_name": "Algeria", "zh_name": "阿尔及利亚", "dh": "DZ", "code": 213},
	{"en_name": "American Samoa", "zh_name": "美属萨摩亚", "dh": "AS", "code": 1684},
	{"en_name": "Andorra", "zh_name": "安道尔", "dh": "AD", "code": 376},
	{"en_name": "Angola", "zh_name": "安哥拉", "dh": "AO", "code": 244},
	{"en_name": "Anguilla", "zh_name": "安圭拉", "dh": "AI", "code": 1264},
	{"en_name": "Antigua and Barbuda", "zh_name": "安提瓜和巴布达", "dh": "AG", "code": 1268},
	{"en_name": "Argentina", "zh_name": "阿根廷", "dh": "AR", "code": 54},
	{"en_name": "Armenia", "zh_name": "亚美尼亚", "dh": "AM", "code": 374},
	{"en_name": "Aruba", "zh_name": "阿鲁巴", "dh": "AW", "code": 297},
	{"en_name": "Australia", "zh_name": "澳大利亚", "dh": "AU", "code": 61},
	{"en_name": "Austria", "zh_name": "奥地利", "dh": "AT", "code": 43},
	{"en_name": "Azerbaijan", "zh_name": "阿塞拜疆", "dh": "AZ", "code": 994},
	{"en_name": "Bahamas", "zh_name": "巴哈马", "dh": "BS", "code": 1242},
	{"en_name": "Bahrain", "zh_name": "巴林", "dh": "BH", "code": 973},
	{"en_name": "Bangladesh", "zh_name": "孟加拉国", "dh": "BD", "code": 880},
	{"en_name": "Barbados", "zh_name": "巴巴多斯", "dh": "BB", "code": 1246},
	{"en_name": "Belarus", "zh_name": "白俄罗斯", "dh": "BY", "code": 375},
	{"en_name": "Belgium", "zh_name": "比利时", "dh": "BE", "code": 32},
	{"en_name": "Belize", "zh_name": "伯利兹", "dh": "BZ", "code": 501},
	{"en_name": "Benin", "zh_name": "贝宁", "dh": "BJ", "code": 229},
	{"en_name": "Bermuda", "zh_name": "百慕大群岛", "dh": "BM", "code": 1441},
	{"en_name": "Bhutan", "zh_name": "不丹", "dh": "BT", "code": 975},
	{"en_name": "Bolivia", "zh_name": "玻利维亚", "dh": "BO", "code": 591},
	{"en_name": "Bosnia and Herzegovina", "zh_name": "波斯尼亚和黑塞哥维那", "dh": "BA", "code": 387},
	{"en_name": "Botswana", "zh_name": "博茨瓦纳", "dh": "BW", "code": 267},
	{"en_name": "Brazil", "zh_name": "巴西", "dh": "BR", "code": 55},
	{"en_name": "Brunei", "zh_name": "文莱", "dh": "BN", "code": 673},
	{"en_name": "Bulgaria", "zh_name": "保加利亚", "dh": "BG", "code": 359},
	{"en_name": "Burkina Faso", "zh_name": "布基纳法索", "dh": "BF", "code": 226},
	{"en_name": "Burundi", "zh_name": "布隆迪", "dh": "BI", "code": 257},
	// {"en_name": "Canada", "zh_name": "加拿大", "dh": "CA", "code": 1},
	{"en_name": "Cambodia", "zh_name": "柬埔寨", "dh": "KH", "code": 855},
	{"en_name": "Cameroon", "zh_name": "喀麦隆", "dh": "CM", "code": 237},
	{"en_name": "Cayman Islands", "zh_name": "开曼群岛", "dh": "KY", "code": 1345},
	{"en_name": "Central African Republic", "zh_name": "中非共和国", "dh": "CF", "code": 236},
	{"en_name": "Ivory Coast", "zh_name": "科特迪瓦", "dh": "CI", "code": 225},
	{"en_name": "Chad", "zh_name": "乍得", "dh": "TD", "code": 235},
	{"en_name": "Chile", "zh_name": "智利", "dh": "CL", "code": 56},
	{"en_name": "Colombia", "zh_name": "哥伦比亚", "dh": "CO", "code": 57},
	{"en_name": "Cape Verde", "zh_name": "佛得角", "dh": "CV", "code": 238},
	{"en_name": "Comoros", "zh_name": "科摩罗", "dh": "KM", "code": 269},
	{"en_name": "Cook Islands", "zh_name": "库克群岛", "dh": "CK", "code": 682},
	{"en_name": "Costa Rica", "zh_name": "哥斯达黎加", "dh": "CR", "code": 506},
	{"en_name": "Cuba", "zh_name": "古巴", "dh": "CU", "code": 53},
	{"en_name": "Croatia", "zh_name": "克罗地亚", "dh": "HR", "code": 385},
	{"en_name": "Cyprus", "zh_name": "塞浦路斯", "dh": "CY", "code": 357},
	{"en_name": "Czech", "zh_name": "捷克", "dh": "CZ", "code": 420},
	{"en_name": "Denmark", "zh_name": "丹麦", "dh": "DK", "code": 45},
	{"en_name": "Djibouti", "zh_name": "吉布提", "dh": "DJ", "code": 253},
	{"en_name": "Dominica", "zh_name": "多米尼克", "dh": "DM", "code": 1767},
	{"en_name": "Dominican Republic", "zh_name": "多米尼加共和国", "dh": "DO", "code": 1809},
	{"en_name": "Ecuador", "zh_name": "厄瓜多尔", "dh": "EC", "code": 593},
	{"en_name": "Egypt", "zh_name": "埃及", "dh": "EG", "code": 20},
	{"en_name": "El Salvador", "zh_name": "萨尔瓦多", "dh": "SV", "code": 503},
	{"en_name": "Equatorial Guinea", "zh_name": "赤道几内亚", "dh": "GQ", "code": 240},
	{"en_name": "Eritrea", "zh_name": "厄立特里亚", "dh": "ER", "code": 291},
	{"en_name": "Estonia", "zh_name": "爱沙尼亚", "dh": "EE", "code": 372},
	{"en_name": "Ethiopia", "zh_name": "埃塞俄比亚", "dh": "ET", "code": 251},
	{"en_name": "Faroe Islands", "zh_name": "法罗群岛", "dh": "FO", "code": 298},
	{"en_name": "Fiji", "zh_name": "斐济", "dh": "FJ", "code": 679},
	{"en_name": "Finland", "zh_name": "芬兰", "dh": "FI", "code": 358},
	{"en_name": "France", "zh_name": "法国", "dh": "FR", "code": 33},
	{"en_name": "French Guiana", "zh_name": "法属圭亚那", "dh": "GF", "code": 594},
	{"en_name": "French Polynesia", "zh_name": "法属波利尼西亚", "dh": "PF", "code": 689},
	{"en_name": "Gabon", "zh_name": "加蓬", "dh": "GA", "code": 241},
	{"en_name": "Gambia", "zh_name": "冈比亚", "dh": "GM", "code": 220},
	{"en_name": "Georgia", "zh_name": "格鲁吉亚", "dh": "GE", "code": 995},
	{"en_name": "Germany", "zh_name": "德国", "dh": "DE", "code": 49},
	{"en_name": "Ghana", "zh_name": "加纳", "dh": "GH", "code": 233},
	{"en_name": "Gibraltar", "zh_name": "直布罗陀", "dh": "GI", "code": 350},
	{"en_name": "Greece", "zh_name": "希腊", "dh": "GR", "code": 30},
	{"en_name": "Greenland", "zh_name": "格陵兰岛", "dh": "GL", "code": 299},
	{"en_name": "Grenada", "zh_name": "格林纳达", "dh": "GD", "code": 1473},
	{"en_name": "Guadeloupe", "zh_name": "瓜德罗普岛", "dh": "GP", "code": 590},
	{"en_name": "Guam", "zh_name": "关岛", "dh": "GU", "code": 1671},
	{"en_name": "Guatemala", "zh_name": "危地马拉", "dh": "GT", "code": 502},
	{"en_name": "Guinea", "zh_name": "几内亚", "dh": "GN", "code": 224},
	{"en_name": "Guinea-Bissau", "zh_name": "几内亚比绍共和国", "dh": "GW", "code": 245},
	{"en_name": "Guyana", "zh_name": "圭亚那", "dh": "GY", "code": 592},
	{"en_name": "Haiti", "zh_name": "海地", "dh": "HT", "code": 509},
	{"en_name": "Honduras", "zh_name": "洪都拉斯", "dh": "HN", "code": 504},
	{"en_name": "Hong Kong (China)", "zh_name": "中国香港", "dh": "HK", "code": 852},
	{"en_name": "Hungary", "zh_name": "匈牙利", "dh": "HU", "code": 36},
	{"en_name": "Iceland", "zh_name": "冰岛", "dh": "IS", "code": 354},
	{"en_name": "India", "zh_name": "印度", "dh": "IN", "code": 91},
	{"en_name": "Indonesia", "zh_name": "印度尼西亚", "dh": "ID", "code": 62},
	{"en_name": "Iraq", "zh_name": "伊拉克", "dh": "IQ", "code": 964},
	{"en_name": "Ireland", "zh_name": "爱尔兰", "dh": "IE", "code": 353},
	{"en_name": "Iran", "zh_name": "伊朗", "dh": "IR", "code": 98},
	{"en_name": "Israel", "zh_name": "以色列", "dh": "IL", "code": 972},
	{"en_name": "Italy", "zh_name": "意大利", "dh": "IT", "code": 39},
	{"en_name": "Jamaica", "zh_name": "牙买加", "dh": "JM", "code": 1876},
	{"en_name": "Japan", "zh_name": "日本", "dh": "JP", "code": 81},
	{"en_name": "Jordan", "zh_name": "约旦", "dh": "JO", "code": 962},
	{"en_name": "Kazakhstan", "zh_name": "哈萨克斯坦", "dh": "KZ", "code": 997},
	{"en_name": "Kenya", "zh_name": "肯尼亚", "dh": "KE", "code": 254},
	{"en_name": "Kiribati", "zh_name": "基里巴斯", "dh": "KI", "code": 686},
	{"en_name": "Kuwait", "zh_name": "科威特", "dh": "KW", "code": 965},
	{"en_name": "Kyrgyzstan", "zh_name": "吉尔吉斯斯坦", "dh": "KG", "code": 996},
	{"en_name": "Laos", "zh_name": "老挝", "dh": "LA", "code": 856},
	{"en_name": "Latvia", "zh_name": "拉脱维亚", "dh": "LV", "code": 371},
	{"en_name": "Lebanon", "zh_name": "黎巴嫩", "dh": "LB", "code": 961},
	{"en_name": "Lesotho", "zh_name": "莱索托", "dh": "LS", "code": 266},
	{"en_name": "Liberia", "zh_name": "利比里亚", "dh": "LR", "code": 231},
	{"en_name": "Libya", "zh_name": "利比亚", "dh": "LY", "code": 218},
	{"en_name": "Liechtenstein", "zh_name": "列支敦士登", "dh": "LI", "code": 423},
	{"en_name": "Lithuania", "zh_name": "立陶宛", "dh": "LT", "code": 370},
	{"en_name": "Luxembourg", "zh_name": "卢森堡", "dh": "LU", "code": 352},
	{"en_name": "Macau (China)", "zh_name": "中国澳门", "dh": "MO", "code": 853},
	{"en_name": "Macedonia", "zh_name": "马其顿", "dh": "MK", "code": 389},
	{"en_name": "Madagascar", "zh_name": "马达加斯加", "dh": "MG", "code": 261},
	{"en_name": "Malawi", "zh_name": "马拉维", "dh": "MW", "code": 265},
	{"en_name": "Malaysia", "zh_name": "马来西亚", "dh": "MY", "code": 60},
	{"en_name": "Maldives", "zh_name": "马尔代夫", "dh": "MV", "code": 960},
	{"en_name": "Mali", "zh_name": "马里", "dh": "ML", "code": 223},
	{"en_name": "Malta", "zh_name": "马耳他", "dh": "MT", "code": 356},
	{"en_name": "Martinique", "zh_name": "马提尼克", "dh": "MQ", "code": 596},
	{"en_name": "Mariana lslands", "zh_name": "马里亚纳群岛", "dh": "MP", "code": 1670},
	{"en_name": "Mauritania", "zh_name": "毛里塔尼亚", "dh": "MR", "code": 222},
	{"en_name": "Mauritius", "zh_name": "毛里求斯", "dh": "MU", "code": 230},
	{"en_name": "Mayotte", "zh_name": "马约特", "dh": "YT", "code": 262},
	{"en_name": "Mexico", "zh_name": "墨西哥", "dh": "MX", "code": 52},
	{"en_name": "Moldova", "zh_name": "摩尔多瓦", "dh": "MD", "code": 373},
	{"en_name": "Monaco", "zh_name": "摩纳哥", "dh": "MC", "code": 377},
	{"en_name": "Mongolia", "zh_name": "蒙古", "dh": "MN", "code": 976},
	{"en_name": "Montenegro", "zh_name": "黑山", "dh": "ME", "code": 382},
	{"en_name": "Montserrat", "zh_name": "蒙特塞拉特岛", "dh": "MS", "code": 1664},
	{"en_name": "Morocco", "zh_name": "摩洛哥", "dh": "MA", "code": 212},
	{"en_name": "Mozambique", "zh_name": "莫桑比克", "dh": "MZ", "code": 258},
	{"en_name": "Myanmar", "zh_name": "缅甸", "dh": "MM", "code": 95},
	{"en_name": "Namibia", "zh_name": "纳米比亚", "dh": "NA", "code": 264},
	{"en_name": "Nepal", "zh_name": "尼泊尔", "dh": "NP", "code": 977},
	{"en_name": "Netherlands", "zh_name": "荷兰", "dh": "NL", "code": 31},
	{"en_name": "Netherlands Antilles", "zh_name": "荷属安德列斯群岛", "dh": "AN", "code": 599},
	{"en_name": "New Caledonia", "zh_name": "新喀里多尼亚", "dh": "NC", "code": 687},
	{"en_name": "New Zealand", "zh_name": "新西兰", "dh": "NZ", "code": 64},
	{"en_name": "Nicaragua", "zh_name": "尼加拉瓜", "dh": "NI", "code": 505},
	{"en_name": "Niger", "zh_name": "尼日尔", "dh": "NE", "code": 227},
	{"en_name": "Nigeria", "zh_name": "尼日利亚", "dh": "NG", "code": 234},
	{"en_name": "Norway", "zh_name": "挪威", "dh": "NO", "code": 47},
	{"en_name": "Norfolk Island", "zh_name": "诺福克岛", "dh": "NF", "code": 6723},
	{"en_name": "Oman", "zh_name": "阿曼", "dh": "OM", "code": 968},
	{"en_name": "Pakistan", "zh_name": "巴基斯坦", "dh": "PK", "code": 92},
	{"en_name": "Palau", "zh_name": "帕劳", "dh": "PW", "code": 680},
	{"en_name": "Palestine", "zh_name": "巴勒斯坦", "dh": "BL", "code": 970},
	{"en_name": "Panama", "zh_name": "巴拿马", "dh": "PA", "code": 507},
	{"en_name": "Papua New Guinea", "zh_name": "巴布亚新几内亚", "dh": "PG", "code": 675},
	{"en_name": "Paraguay", "zh_name": "巴拉圭", "dh": "PY", "code": 595},
	{"en_name": "Peru", "zh_name": "秘鲁", "dh": "PE", "code": 51},
	{"en_name": "Philippines", "zh_name": "菲律宾", "dh": "PH", "code": 63},
	{"en_name": "Poland", "zh_name": "波兰", "dh": "PL", "code": 48},
	{"en_name": "Portugal", "zh_name": "葡萄牙", "dh": "PT", "code": 351},
	{"en_name": "Puerto Rico", "zh_name": "波多黎各", "dh": "PR", "code": 1787},
	{"en_name": "Korea N. Dem.Peoples Rep.", "zh_name": "朝鲜", "dh": "KP", "code": 850},
	{"en_name": "Qatar", "zh_name": "卡塔尔", "dh": "QA", "code": 974},
	{"en_name": "Republic Of The Congo", "zh_name": "刚果共和国", "dh": "CG", "code": 242},
	{"en_name": "The Democratic Republic of the Congo", "zh_name": "刚果民主共和国（扎伊尔）", "dh": "CD", "code": 243},
	{"en_name": "Romania", "zh_name": "罗马尼亚", "dh": "RO", "code": 40},
	{"en_name": "Russia", "zh_name": "俄罗斯", "dh": "RU", "code": 7},
	{"en_name": "Rwanda", "zh_name": "卢旺达", "dh": "RW", "code": 250},
	{"en_name": "Saint Kitts and Nevis", "zh_name": "圣基茨和尼维斯", "dh": "KN", "code": 1869},
	{"en_name": "Saint Lucia", "zh_name": "圣卢西亚", "dh": "LC", "code": 1758},
	{"en_name": "Saint Pierre and Miquelon", "zh_name": "圣彼埃尔和密克隆岛", "dh": "PM", "code": 508},
	{"en_name": "Saint Vincent and The Grenadines", "zh_name": "圣文森特和格林纳丁斯", "dh": "VC", "code": 1784},
	{"en_name": "Samoa", "zh_name": "萨摩亚", "dh": "WS", "code": 685},
	{"en_name": "San Marino", "zh_name": "圣马力诺", "dh": "SM", "code": 378},
	{"en_name": "Sao Tome and Principe", "zh_name": "圣多美和普林西比", "dh": "ST", "code": 239},
	{"en_name": "Saudi Arabia", "zh_name": "沙特阿拉伯", "dh": "SA", "code": 966},
	{"en_name": "Senegal", "zh_name": "塞内加尔", "dh": "SN", "code": 221},
	{"en_name": "Serbia", "zh_name": "塞尔维亚", "dh": "RS", "code": 381},
	{"en_name": "Seychelles", "zh_name": "塞舌尔", "dh": "SC", "code": 248},
	{"en_name": "Syrian Arab Republic", "zh_name": "叙利亚", "dh": "SY", "code": 963},
	{"en_name": "Sierra Leone", "zh_name": "塞拉利昂", "dh": "SL", "code": 232},
	{"en_name": "Singapore", "zh_name": "新加坡", "dh": "SG", "code": 65},
	{"en_name": "Saint Maarten (Dutch Part)", "zh_name": "圣马丁岛（荷兰部分）", "dh": "SX", "code": 1721},
	{"en_name": "Slovakia", "zh_name": "斯洛伐克", "dh": "SK", "code": 421},
	{"en_name": "Slovenia", "zh_name": "斯洛文尼亚", "dh": "SI", "code": 386},
	{"en_name": "Solomon Islands", "zh_name": "所罗门群岛", "dh": "SB", "code": 677},
	{"en_name": "Somalia", "zh_name": "索马里", "dh": "SO", "code": 252},
	{"en_name": "South Africa", "zh_name": "南非", "dh": "ZA", "code": 27},
	{"en_name": "South Korea", "zh_name": "韩国", "dh": "KR", "code": 82},
	{"en_name": "Spain", "zh_name": "西班牙", "dh": "ES", "code": 34},
	{"en_name": "Sri Lanka", "zh_name": "斯里兰卡", "dh": "LK", "code": 94},
	{"en_name": "Suriname", "zh_name": "苏里南", "dh": "SR", "code": 597},
	{"en_name": "Swaziland", "zh_name": "斯威士兰", "dh": "SZ", "code": 268},
	{"en_name": "Sweden", "zh_name": "瑞典", "dh": "SE", "code": 46},
	{"en_name": "Switzerland", "zh_name": "瑞士", "dh": "CH", "code": 41},
	{"en_name": "Taiwan (China)", "zh_name": "中国台湾", "dh": "TW", "code": 886},
	{"en_name": "Tajikistan", "zh_name": "塔吉克斯坦", "dh": "TJ", "code": 992},
	{"en_name": "Tanzania", "zh_name": "坦桑尼亚", "dh": "TZ", "code": 255},
	{"en_name": "Thailand", "zh_name": "泰国", "dh": "TH", "code": 66},
	{"en_name": "Timor-Leste", "zh_name": "东帝汶", "dh": "TL", "code": 670},
	{"en_name": "The Republic of the Sudan", "zh_name": "苏丹", "dh": "SD", "code": 249},
	{"en_name": "The Republic of South Sudan", "zh_name": "南苏丹", "dh": "SS", "code": 211},
	{"en_name": "The Federated States of Micronesia", "zh_name": "密克罗尼西亚", "dh": "FM", "code": 691},
	{"en_name": "Togo", "zh_name": "多哥", "dh": "TG", "code": 228},
	{"en_name": "Tonga", "zh_name": "汤加", "dh": "TO", "code": 676},
	{"en_name": "Trinidad and Tobago", "zh_name": "特立尼达和多巴哥", "dh": "TT", "code": 1868},
	{"en_name": "Tunisia", "zh_name": "突尼斯", "dh": "TN", "code": 216},
	{"en_name": "Turkey", "zh_name": "土耳其", "dh": "TR", "code": 90},
	{"en_name": "Turkmenistan", "zh_name": "土库曼斯坦", "dh": "TM", "code": 993},
	{"en_name": "Turks and Caicos Islands", "zh_name": "特克斯和凯科斯群岛", "dh": "TC", "code": 1649},
	{"en_name": "Uganda", "zh_name": "乌干达", "dh": "UG", "code": 256},
	{"en_name": "Ukraine", "zh_name": "乌克兰", "dh": "UA", "code": 380},
	{"en_name": "United Arab Emirates", "zh_name": "阿拉伯联合酋长国", "dh": "AE", "code": 971},
	{"en_name": "United Kingdom", "zh_name": "英国", "dh": "GB", "code": 44},
	{"en_name": "United States", "zh_name": "美国", "dh": "US", "code": 1},
	{"en_name": "Uruguay", "zh_name": "乌拉圭", "dh": "UY", "code": 598},
	{"en_name": "Uzbekistan", "zh_name": "乌兹别克斯坦", "dh": "UZ", "code": 998},
	{"en_name": "Vanuatu", "zh_name": "瓦努阿图", "dh": "VU", "code": 678},
	{"en_name": "Venezuela", "zh_name": "委内瑞拉", "dh": "VE", "code": 58},
	{"en_name": "Vietnam", "zh_name": "越南", "dh": "VN", "code": 84},
	{"en_name": "Virgin Islands, British", "zh_name": "英属维尔京群岛", "dh": "VG", "code": 1284},
	{"en_name": "Virgin Islands, US", "zh_name": "美属维尔京群岛", "dh": "VI", "code": 1340},
	{"en_name": "Yemen", "zh_name": "也门", "dh": "YE", "code": 967},
	{"en_name": "Zambia", "zh_name": "赞比亚", "dh": "ZM", "code": 260},
	{"en_name": "Zimbabwe", "zh_name": "津巴布韦", "dh": "ZW", "code": 263}
]
