<template>
  <div class="UploadFile">
    <el-upload
      :action="baseUploadURL"
      :show-file-list="showFileList"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-progress="onUploadProgress"
      :on-success="onUploadSuccess"
      :on-error="onUploadError"
      :headers="{ Authorization: token }"
      :data="paramData"
      :accept="accept"
    >
      <slot v-show="showCustomUpload" name="uploader-slot"></slot>
      <div v-show="!showCustomUpload">
        <el-button size="small" type="primary">点击上传</el-button>
        <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
      </div>
    </el-upload>
  </div>
</template>

<script>
import api from "@/api/api";
import { mapState } from "vuex";

let _that;
export default {
  name: "UploadFile",
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
  },
  props: {
    // 是否显示默认文件列表
    showFileList: {
      type: Boolean,
      default: false,
    },

    // 文件列表 showFileList 为 true是生效
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },

    accept: String,

    // 文件上传最大大小, 单位 MB
    maxSize: Number,

    // 超出最大上传大小后提示文字
    maxSizeErrorMsg: String,

    // 展示自定义上传按钮
    showCustomUpload: {
      type: Boolean,
      default: false,
    },

    // 是否显示全屏加载动画
    showLoading: {
      type: Boolean,
      default: true,
    },

    // 上传时附带的额外参数
    paramData: Object,

    baseUploadURL: {
      type: String,
      default: (value) => {
        return value || this.uploadUrl;
      }
    }
  },
  data() {
    return {
      // baseUploadURL: this.uploadUrl,
      uploadApi: api.upload,
      loading: null,
    };
  },
  created() {
    _that = this;
  },
  mounted() {},
  methods: {
    // 上传文件前
    beforeUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      let isLt2M = true;
      if (!!this.maxSize) {
        isLt2M = file.size / 1024 / 1024 < this.maxSize
      }

      // if (!isJPG) {
      //   this.$message.error("上传头像文件只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        // this.$message.error(maxSizeErrorMsg);
        this.utils.reminderMsg(maxSizeErrorMsg, 1);
      }
      this.$emit("beforeUpload", file);
      return isLt2M;
    },
    // 上传文件成功
    onUploadSuccess(res, file) {
      if (this.showLoading) {
        this.loading.close();
      }
      this.$emit("onUploadSuccess", res, file);
    },
    // 上传文件失败
    onUploadError(err, file) {
      if (this.showLoading) {
        this.loading.close();
      }
      this.$emit('onUploadError');
    },
    // 上传文件中
    onUploadProgress(event, file) {
      if (this.showLoading) {
        this.loading = this.$loading({
          lock: true,
          text: this.$t("common.zzsc"),
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      }
      this.$emit('onUploadProgress', event, file);  
    },
    
  },
};
</script>

<style lang="less" scoped>

</style>
