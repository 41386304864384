// 引入api
import api from '../../api/api.js';

// 引入异步请求文件
import http from '../../utils/request.js';

export default {
  state: {
    // 模型类别
    modelClass: [],
  },
  getters: {
  },
  mutations: {
    SET_MODEL_CLASS(state, data) {
      // 变更状态
      state.modelClass = data;
    },
  },
  actions: {
    /**
     * 获取字典
     * @param {*} param0 
     * @param {*} data {dictType: Value}
     * @param {*} data.dictType 'zyy_model_class' 模型类别, 'zyy_model_source' 模型来源
     */ 
    getDict({ commit }, data) {
      console.log('getData123',data);
      let params = {
        dictType: data.dictType,
      };
      http.request(api.dictList, params, 'get').then(res => {
        if (res.code == 200) {
          commit('SET_MODEL_CLASS', res.rows);
        }
      });
    },
  },
  namespaced: true,
}
