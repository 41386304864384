import enLocale from 'element-ui/lib/locale/lang/en'  // elementui 的英文语言包
const en = {
  //打印任务详情
  printTaskDetail: {
    printDetail: 'Print Details',
    printPreview: 'Print Preview',
    videoMonit: 'Video Surveillance',
    noCamera: 'No Camera Available',
    printPars: 'Print Parameters',
    layThick: 'Layer Thickness',
    singLayExpTime: 'Single Layer Exposure Time',
    bottomLayExpTime: 'Bottom Layer Exposure Time',
    expDelay: 'Exposure Delay',
    bottomLayExpLay: 'Bottom Layer Exposure Layers',
    bottomLayExpTranLay: 'Bottom Lay-Exposure Transition Layers',
    liftHeight1: 'Lift Height 1',
    lifgSpeed1: ' Lift Speed 1',
    liftHeight2: 'Lift Height 2',
    lifgSpeed2: ' Lift Speed 2',
    lowerSpeed: 'Lowering Speed',
    zAxLiftAfterPrint: 'Z-Axis Lift After Print',
    continuePrint: 'Continue Printing',
    continuePrinting: 'In recovery',
  },
  //公共的
  common: {
    download: 'Download',
    all: 'All',
    loading: 'Loading',
    noData: 'There is currently no data available',
    addImg: 'Add Picture',
    zzsc: 'Uploading',
    submit: 'Submit',
    cancel: 'Cancel',
    submit_success: 'Submitted successfully',
    confirm: 'Confirm',
    qxz: 'Please select',
    qsr: 'Please enter',
    search: 'Search',
    delete: 'Delete',
    hint: 'kind reminder',
    home: 'Home',
    zztj: 'Be submitting',
    noMore: "There's no more",
    deleting: 'Deleting',
    time: 'Time',
    imgFormatErr: 'The image format can only be',
    czcg: 'operate successfully',
    save: 'Save',
    ckxq: 'View details',
    shouqi: 'Pack up',
    zhankai: 'Unfold',
    zwmx: 'No model',
    zwsp: 'No commodity',
    weChat: ' WeChat ',
    zyhydd: ' Octopus core mobile end ',
  },
  header: {
    fileUpload: 'Files upload',
    uploadModel: 'Upload Model',
    workbench: 'Workbench',
    shoppingCart: 'shopping Cart',
    day: 'Days',
    register: 'Register',
    login: 'Login',
    modelBase: 'Model base',
    modelColumn: 'Model Column',
    productLibrary: 'Shopping mall',
    searchPlaceholder: 'Search for what you are interested in',
    becomeDue: 'Expires',
    renew: 'Renew',
    index: 'Home',

    bdsj: 'Bind mobile phone',
    bd: 'Bind',

    smdl: 'Scan code login',
    qsy: 'Please use',
    smewm: 'Scan QR code',

  },
  login: {
    title: 'Login to your Octopus Core account',
    myzhmfzc: 'No account? Free registration',
    sjyzmdl: 'Login with mobile verification code',
    yxyzmdl: 'Login with Email verification code',
    zhmmdl: 'Login with Mii number and password',
    qsrsjh: 'Please enter mobile number',
    qsryx: 'Please enter email',
    qsrzqyx: 'Please enter a valid E-Mail',
    qsrsjhOryx: 'Please enter your phone number/email address',
    qsrzqsjh: 'Please enter a valid mobile number/E-Mail',
    qsrzqsjh02: 'Please enter a valid mobile number',
    qsryzm: 'Please enter the verification code',
    qsrmm: 'Please enter the password',
    hqyzm: 'Get verification code',
    fsz: 'Sending',
    khq: 'S Available after',
    tyysxy: 'Please agree to the user and privacy policy',
    grzx: 'Personal Center',
    tcdl: 'exit',
    smcg: 'Successful code scanning',
    qzsjsqr: 'Please confirm on your phone',
    xczddl: 'Auto login next time',
    loginText: 'Login',
    agreementText01: 'I have read and agree to the Octopus Core',
    agreementText02: '"User Agreement"',
    agreementText03: 'and',
    agreementText04: '"Privacy Policy"',
    otherLogin: 'Other login methods',
    wxLogin: 'Login with WeChat',
    qqLogin: 'Login with QQ',
    weiboLogin: 'Login with WeiBo',
    srsjhOryx: 'Phone number/email',
    srndmm: 'Please enter your password(6-18 words)',
    wjmm: 'Forget the Password?',
    qdtcm: 'Really wanna quit? Continue going on?'
  },
  home: {
    tj: 'Recommand',
    zx: 'Newest',
    zr: 'Hottest',
    gd: 'More',
    zhpx: 'Comprehensive sorting',
    cymxzl: 'Creativity · Model Column',
    hl3Dmx: 'Massive 3D models',
    gdzl: 'More columns',
  },
  zhuanLan: {
    title: 'All model columns',
    noZhuanLan: 'No column',
  },
  // 模型详情
  modelDetail: {
    scy: 'Updated on',
    zyb: 'Octopus Coins',
    fl: 'Type',
    mf: 'Free',
    collect_success: 'Collection successful',
    collect_cancel: 'Canceled successfully',
    dbxz: 'Package download',
    plxz: 'Batch Download',
    yjqp: 'One click slicing',
    mxms: 'Description',
    hyzx: 'VIP special extra',
    hyzx_desc: 'Upgrade membership acquisition and download massive model decisions.',
    ktvip: 'Activate membership',
    wj: 'File',
    spxg: 'Real picture',
    pl: 'Comments',
    qpg01: '',
    qpg02: 'Model files in total',
    gsp: 'Live shots in total',
    tpl: 'Comments in total',
    yl: 'Preview',
    qp: 'Sliced',
    download: 'Downloads',
    fsp: 'Send real photos',
    fbspxg: 'Release Real Shot Effects',
    sctp: 'Upload image',
    qscsptp: 'Please upload live photos',
    zan: 'Like',
    huifu: 'Reply',
    xpl: 'Write a comment',
    placeholder1: 'Say something',
    placeholder2: 'Content',
    emoji: 'Expression',
    img: 'Picture',
    qsrplnr: 'Please enter the comment content',
    ljgm: 'Buy Now',
    buy: 'Purchase',
    zybbz: 'Insufficient octopus coins, go recharge',
    yuan: 'yuan',
    qxzzybtc: 'Please choose the octopus coin package',
    gmcg: 'Purchase successful',
    tjmx: 'Recommendation Model',
    hyh: 'Change',
    ydcc: 'Cloud storage',
    bdcc: 'Local storage',
    xzcg: 'Download successful',
		qxzxzwj: 'Please select a download file',
    qxzqpwj: 'Please select slice file',
    sccg: 'Successfully delete',
    qdscmx: 'Are you sure to delete the model?',
    ljqp: 'Slice immediately',
  },


  // 模型发布
  modelPublish: {
    title: 'Upload Model',
    mxly: 'Model Source',
    ycmx: 'Original model',
    fxmx: 'Share Model',
    ecczmx: 'Secondary creation model',
    bqsm: 'Copyright Notice',
    xzgxxy: 'Please select a knowledge sharing license agreement',
    dj: 'Pricing',
    sf: 'Charge',
    mf: 'Free',
    srzybjg: 'Please enter the octopus coin price',
    yczm: 'Original proof',
    yczm_tips: 'Upload the "Work Registration Certificate" or other original proof, and the model will be prioritized for review and recommendation!',
    sczm: 'Upload proof',
    scmx: 'Upload Model',
    add: 'Add',
    file_tips01: 'Up to 500MB sized files currently supported',
    file_tips02: 'Model file formats: ',
    file_type_err: 'The format of the uploaded file is incorrect',
    mxmc: 'Model Name',
    mxmc_placeholder: 'Please enter a name of 60 characters or less',
    mxlb: 'Model Category',
    mxlb_placeholder: 'Please select a model category',
    yssz: 'Privacy settings',
    gk: 'Open',
    zy: 'Self use',
    img_list: 'Photos',
    img_list_tips: 'Up to 10 copies can be uploaded describe',
    sctj: 'Upload Photos',
    qsctj: 'Please upload the Photos',
    desc: 'Describe',
    desc_placeholder: 'Can you briefly describe the model...',
    ck: 'Check',

    qxzmxly: 'Please select a model source',
    qscmxzm: 'Please upload the model proof',
    qscmxwj: 'Please upload the model file',
    qscmxwjslt: 'Please upload a thumbnail of the model file',

    scwc: 'Upload complete',
    scsb: 'Fail to upload',
		cckjbz: 'Your storage space is insufficient, you can expand the storage space in the personal center',
		
    uploadModelCloseMsg: 'Do you want to exit the upload model?',
  },

  //商城
  goodsList: {
    allClass: 'Classify All',
    qxsc: 'Cancel favorite',
    sc_goods: 'Collect',
    yxzsp: 'Already chosen goods',
    jrgwc: 'Add to my favorite',
    cwdshdzxz: 'Choose from my address',
    tjdz: 'Add address',
    qxzdz: 'Please select an address',
    spxq: 'Product Details',
    sppj: 'Product evaluation',
    kf: 'Customer service',
    rmjx: 'Popular recommendations',
    jrpp: 'Entry brand',
    sort: 'Sort',
    zh: 'Comprehensive',
    xl: 'Selling items',
    price: 'Price',
    hpd: 'Evaluation rate',
    zhpj: 'comprehensive assessment',
    leibie: 'Catagories',
    fenlei: 'Parts',
    rfk: 'Already paid',
    ps: 'Delivery',
    fh: 'Shipment',
    yf: 'Postage',
    myf: 'Freight free',
    zwpj: 'No evaluation',
    rsc: 'People collecting',
    qi: 'Rise',
    qdscgdz: 'Are you sure you want to delete this address?',
    kcbz: 'Inventory shortage',
    xtqhsjxxyz: 'Select, Please agree with the merchant first, The merchant will be visible after payment',
    qxzgg: 'Please select specifications',
    zwcp: 'No product',
    ksp: 'Products',
    zhuanqu: 'Prefecture',
  },


  user: {
    qsctx: 'Please upload your avatar',
    qsrnc: 'Enter the name',
    qsrbq: 'Please enter a personalized signature',
    changePwdTitle: 'Change password',
    changePwdTips: 'For the security of your account, we will send a text message verification code to your phone: ',
    sendYzm: 'Send verification code',
    reSendYzm: 'Resend',
    sendCodeSuccessful: 'Verification code sent successfully',
    inputCode: 'Please enter the verification code',
    inputOldPwd: 'Please enter the old password',
    inputNewPwd: 'Please enter your new password',
    inputNewPwdAgain: 'Please enter your new password again',
    pwdDifferent: 'The two passwords are inconsistent. Please re-enter them',
    unableSetPwd: 'You have not yet bound your phone number and cannot set a password',
    pwdLengthTips: 'The password should contain 6-20 alphanumeric characters or symbols.',
    tips: 'Kind reminder',
    cancelAccountTips: 'After cancellation, All data of the account will be destroyed and cannot be retrieved. Are you sure to cancel the account?',
    unbindingWxTips: 'Are you sure you want to unbind wechat?',
    unbindingQqTips: 'Are you sure you want to unbind QQ?',
    unbindingWbTips: 'Are you sure you want to unbind Sina Weibo?',
    confirm: 'Confirm',
    cancel: 'Cancel',
    cancelAccount: 'Account cancellation',
    cancelAccountText: 'After cancellation, All data of the account will be destroyed and cannot be retrieved. Please proceed with the operation with caution.',

    bindPageTitle: 'Bind account',
    currentEmail: 'Current email',
    currentMobile: 'Current TEL number',
    change: 'Replace',
    thirdPartTitle: 'Third party binding account',
    thirdPartNotes: 'Binding a third-party account allows you to directly log in to Grass Fish Core. If the current account is in a login state at the corresponding site, you need to log out before rebinding.',
    binding: 'Binding',
    unbinding: 'Unbind',
    submit: 'Submit',
    inputNewMobile: 'Please enter a new phone number',
    mobileFormat: 'The format of the mobile phone number is incorrect',
    changeEmailTips: 'For the security of your account, we will send a verification code to your email:',
    inputNewEmail: 'Please enter your new email address',
    inputEmailCode: 'Please enter your new email verification code',
    emailFormat: 'The mailbox format is incorrect',
    emailRepeat: 'The mailbox format is incorrect',
    emailVerified: 'Vertified',
    storageRule: 'Storage space rules',
    storageTitle: 'Free exclusive 5GB of space',
    storageTime: 'Permanently valid',
    rule: 'Rules',
    freeSpace: 'Available Space',

    qpcc: 'Slice Size',
    qpdx: 'Slice Size',
    qpcs: 'Slice Layers',
    tier: 'Layers',
    jx: 'Model',
    xq: 'Details',

    grzx: 'My Center',
    grzl: 'Personal Data',
    grsz: 'Personal Settings',
    sctx: 'Upload Avatar',
    nickname: 'Nickname',
    qsr_nickname: 'Please enter a nickname / name',
    bq: 'Mood',
    hdqmhrgdrkjno: 'A good signature will allow more people to see you',
    yzsjh: 'Verify phone number',
    ghsjh: 'Change phone number',
    bdsjh: 'Bind account to a mobile phone number',
    szzh: 'Set up an account',
    xgmm: 'Change password',
    yzyx: 'Verify email',
    bdyx: 'Bind the E-Mail',
    ghyx: 'Change the E-Mail',

    yykj: 'Used Space：',
    dq: 'Expires',

    qp_cancel: 'Canceled successfully',

    wddd: 'My Orders',
    wdmx: 'My Models',
    wdqp: 'My Slices',
    wdsc: 'My Favorites',
    wdpj: 'Merchandise order evaluation',

    mx: 'Models',
    ssmxmc: 'Search Model Name',
    scmx: 'Upload Model',
    cckj: 'Storage Space',
    zh: 'Account',
    copy: 'Copy',
    zyb: 'Octopus Coins',
    ycc: 'Saved in the cloud',

    qsrsjh: 'Please enter mobile number',

    wdzyb: 'My octopus coins',
    qpzt: 'Slice Status',
    qpwxy: 'Slicing response, please wait',
    yqx: 'Canceled',
    cczjqxqprw: 'This operation will cancel the slicing task. Do you want to continue?',
    zwqpsj: 'No slicing data currently available',
    hp: 'Positive rating',
    cp: 'Negative rating',
    zp: 'Medium rating',
    content: 'Content',
    img: 'Picture',

    priority: 'Priority',
    vipyxpdz: 'Member priority in line',
    lxkf: 'Contact customer service',
    gzsj: 'Working hours',
    txyj: 'Fill in the feedback',

    qpsb: 'Slice failure',
    bkqx: 'Can not be cancelled',
    ckqb: 'View all',
    zwmxwjksc: 'There are no model files to delete',
    sctx_size: 'Upload profile picture size can not exceed 2MB!',
    modelsize: 'Size',
    xzsj: 'Download time',

    smbd: 'Scanning binding',
    qsywxsm: 'Please use "wechat scan QR Code"',
    crcckj: 'Expanding storage space',
    kthyxgdkj: 'Open members to enjoy more space',

    chongZhi: 'Top Up',
    qbmx: 'Wallet details',
    yxz: 'Downloaded',
    wqp: "Unsliced",
    yqp: "Sliced",
    scdycc: "Upload to cloud storage",
    ysc: "Uploaded",
    cczjscmxwj: "This operation will delete all files under the model",
    sfjx: "Whether to continue?",
    cczjscmxwj02: "This action will delete the model file",
    cczjscmxwj03: "This deletes the model upload record",
    yjpdsj: "Expected queue time in queue",
    cxqp: "Reslice",
    addTime: "Add time",
    scqprw: "This operation will delete the slicing task",
    qxqprw: "This will cancel the slicing task",
    merchant: "Merchant",
    zwsj: 'No Merchant',
    zwpjsj: 'No evaluation data is available',

    tpbncg: 'Images cannot exceed',
    tpzdsc: 'You can upload a maximum of 9 photos',
    pjcg: 'Evaluate Success',
    fbpjz: 'In the comments',

    user_yqp: "Cloud Slice",
    user_rjqp: "Software Slicing",

    user_cxyh: "Reoptimize",
    user_cxyh_tips: "Successfully started optimization, please wait for optimization to complete",
  },


  orders: {
    qbdd: 'All orders',
    j6yndd: 'Orders within the past six months',
    sixyqdd: 'Orders six months ago',
    sp: 'Goods',
    sl: 'Quantity',
    sjzf: 'Actual Payment',
    ly: 'Leave a message',
    zt: 'State',
    jycz: 'Exchange Manipulate',
    ddh: 'order number',
    ddh02: 'Order NO',
    none: 'None',
    dzf: 'To be paid',
    jygb: 'Close Transaction',
    dfh: 'Pending Shipments',
    dsh: 'Pending Receipt',
    dpj: 'To be evaluated',
    ywc: 'Completed',
    tksh: 'Refund/Aftersales',
    pdz: 'Be queuing',
    qpz: 'In slices',

    qfk: 'Go to pay',
    qxdd: 'Cancel the order',
    xgdz: 'Change of delivery address',
    shdz: 'Delivery address',
    shou: 'Received by',
    syxdz: 'Use the new address',
    xgbdz: 'Modify the current address',
    qdxg: 'Confirm modification',
    ddxq: 'Details of the order',
    qxyy: 'Reason for cancellation',
    qxzqxyy: 'Select reason for cancellation',
    wxts: 'Kind remind',
    ddcgqxhwfhf: 'The order cannot be restored after successful cancellation',
    ddcgqxhwfhf02: 'After the order is successfully cancelled, the paid amount of the order will be returned to your payment account, and the refund will be completed within 5 working days after the cancellation date',
    zbqx: 'Not canceled temporarily',
    qdqx: 'Confirm cancellation',
    qxzqxyy: 'Please select the reason for cancellation',
    qxcg: 'Canceled successfully',
    qxz: 'Canceling',
    dd_tips01: 'Please complete the payment within ',
    dd_tips02: '.',
    minutes: 'Minutes',
    seconds: 'Seconds',
    tjdd: 'Submit order',
    fkcg: 'Payment successful',
    sjfh: 'Merchant shipment',
    qrsh: 'Confirm receipt',
    ddxx: 'Order information',
    mjly: 'Buyer’s message',
    tjsj: 'Submit time',
    spxq: 'Good details',
    cz: 'Manipulate',
    spzj: 'Amount to',
    yf: 'Delivery fee',
    kd: 'Deliver',
    fksj: 'Payment time',
    fhsj: 'Delivery time',
    shsj: 'Receiving time',
    wcsj: 'Completion time',
    qxsj: 'Cancel time',
    pg: 'Package',
    ydh: 'Express NO',
    ckwl: 'View Logistics',

    tkcg: 'Refund successful',
    sjyty: 'Merchant has agreed',
    ddsjsh: 'Waiting for merchant to receive goods',
    cxsq: 'Revoke application',
    tkshxq: 'Refund / After sales details',
    mjsqtk: 'Buyer requests refund',
    mjclthsq: 'Seller processing return application',
    tkwb: 'Refund completed',
    tkxq: 'Refund details',
    cjsj: 'Closing time',
    unitPrice: 'Unit price',
    youFei: 'Delivery price',
    tkbh: 'Refund number',
    tkje: 'Refund amount',
    sqjs: 'Number of applications',
    yuan_yin: 'Reason',
    yao_qiu: 'Description of requirements',
    shuo_ming: 'Notes',
    mjchsq: 'Buyer withdraws application',
    sjjjtk: 'Merchant Refuses Refund',
    jjyy: 'Reason for rejection',
    mjth: 'Buyer return',
    scdd: 'Delete the order',
    qdyscgddm: 'Are you sure you want to delete the order?',
    tkdzqx01: 'The refund will be refunded to your account within',
    tkdzqx02: 'Working days',

    qrdd: 'Confirm the order',
    swmrdz: 'Set as your default address',
    mrdz: 'Default address',
    gmjly: 'Leave a message to the seller',
    xzshdz: 'Add shipping address',
    tjdz: 'Add address',
    bjdz: 'Edit address',
    shr: 'Recipient',
    sjhm: 'TEL',
    shdz: 'Address',
    xzssq: 'Please choose province /city /street',
    xzgjdq: 'Select country / region',
    bcbsy: 'Add Address Save and Use',
    qsrshrmc: 'Please enter the recipient name',
    qsrshrdh: "Please enter the recipient's phone number",
    qxzshdz: 'Please select a shipping address',
    qsrshrxxdz: "Please enter the recipient's detailed address",
    szcg: 'Successfully set',
    jsz: 'Send to',
    hyf: 'Combined with shipping cost',
    bhyf: 'Exclude freight',
    spzt: 'Item status',
    qxztklx: 'Please select a refund type',
    qxztkyy: 'Please select the reason for refund',
    qxzkdgs: 'Please select a Courier',
    qtxwldh: 'Please fill in the tracking number',
    xgcg: 'Modify successfully',
    xgsb: 'Change failed',
    sqcg: 'Application approved',
    shcg: 'Successful receipt of goods',
    tkpz: 'Refund voucher',

    cfh: 'Urge delivery',
    qrsh: 'Confirm receipt',
    ckwl: 'Check logistics',
    qpj: 'To evaluate',
    shdh: 'After-sales order number',
    wsh: 'Not after sale',
    shz: 'After sale',
    shwc: 'After sale completion',
    yjj: 'Declined',
    tkthcg: 'Refund successfully returned',
    dcl: 'Pending',
    ddhj: 'Wait for return',
    jjsq: 'refuse an application',
    lxpt: 'Contact platform',
    reapply: 'Reapply',

    sqtk: 'Apply for refund',
    tklx: 'Refund type',
    xztklx: 'Select a refund type',
    tkyy: 'Refund reason',
    xztkyy: 'Select refund reasons',
    tksm: 'Refund instructions',
    qsrtkyy: 'Please enter the reason for refund',
    srjdhspm: 'Enter the name of the street, town, or commodity',
    lrsztayg: "For example: Shenzhen Tian 'an Cloud Valley",
    nytjtksqddcl: 'You have submitted a refund request and are waiting for the merchant to process it',
    tytk: 'Agreed Refund',
    tytk_tips011: 'Merchant has agreed to refund. Please return the product',
    tytk_tips012: 'Send back goods within',
    tytk_tips02: 'The refund item has been sent out, and the merchant will process the refund for you as soon as they receive it',
    tytk_tips03: 'The merchant has agreed to your refund request. The refund amount has been returned to the original payment method.',
    tytk_tips04: 'The merchant has rejected your refund request',
    tytk_tips05: 'You have already sold your refund request. You can reissue the refund request',
    thdz: 'return address',
    txwldh: 'Fill in the logistics number',
    order_qrshddts: "After sale goods in the order will automatically cancel the refund application, whether to confirm the receipt of goods",
    kddh: "Delivery number",
    kdgs: "Express Company",
    jtk: "Refund only",
    thtk: "Return and refund",
    jihui: "Send back",
    qsrkddh: "Please enter the delivery number",
    sfcxgshdd: "Whether to cancel the after-sales order?",
    cxz: "Being revoked",

    gn: 'Domestic',
    hw: 'Overseas',
  },

  // 评价订单
  commentOrder: {
    pjdd: 'Evaluate',
    sppf: 'Evaluate the goods',
    pjsd: 'Share your Products',
    fxtyxd_content: 'Share your mood now and give a reference for other buyers~',
    zdksc: 'You can upload 9 pics maximum that lower than 10MB',
    fbpl: 'Send',
    plnrbnwk: 'Comments cannot be empty',
  },

  // 物流
  logistics: {
    gsp: 'Item',
  },
  other: {
    joinTip: 'Individual users can quickly contact us through online customer service. You can also contact us through the following methods:',
  },
  // 工作台
  workbenches: {

    startPrint: 'Printing started',
    paused: 'Paused',
    starting: 'Starting',
    abortingPrinting: 'Aborting Printing',
    stopTaskDialog: {
      tip: 'Tip',
      txt: 'Do you want to abort printing?',
      confirm: 'Confirm',
      cancel: 'Cancel'
    },


    unBindingSuc: 'Unbinding successful',
    unBindingFail: 'Unbinding failed',
    tip: 'Tip',
    unBindingTxt: 'Do you want to unbind this device?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    gzt: 'Workbench',
    kx: 'Free',
    jxz: 'In progress',
    zt: 'Halt',
    ywc: 'Completed',
    dkj: 'Off-line',
    gz: 'Glitze',
    wwc: 'Unfinished',
    zz: 'Termination',

    copy: 'Copy',
    copy_success: 'Copy successful',
    dysb: 'Printing device',
    dyrw: 'Printing Task',
    lsrw: 'History tasks',
    cjdyrw: 'Create printing tasks',
    sbzt: 'Device status',
    jx: 'Model',
    sssbmc: 'Search for device name/ID',
    tjsb: 'Add device',
    dycs: 'Printing layers',
    ssrwmc: 'Search task name',
    sbmc: 'Device Name',
    qsrsbmc: 'Please enter a device name',
    qsrsbid: 'Please enter the device ID',

    // 添加打印任务
    rwmc: 'Task name',
    rwmcbnwk: 'The task name cannot be empty',
    yxqp: 'Selected slices',
    cc: 'Size',
    dysc: 'Printing duration',
    xzsb: 'Select Device',
    qxz: 'Go to select',
    tjqp: 'Add Slice',
    xyb: 'Next step',
    qsrrwmc: 'Please enter a task name',
    qtjqp: 'Please add slices',
    qxzdysb: 'Please select a printing device',

    ddy: 'To be printed',
    dyz: 'Printing',
    gzz: 'Working',
		ygb: 'Terminated',
    csz: 'In transmitting',

    dyhs: 'Printing time consumption',
    dyjd: 'Print progress',
    gghqpwj: 'Slice file',
    fen: 'Copy',
    device: 'Equipment',
    tai: 'Copy',
    dyzsc: 'Total printing time',
    fz: 'Minutes',
    gghhcyl: 'Consumption of consumables',
    ydysj: 'Printed time',
    sysj: 'Remaining time',
    yjsyhc: 'Expected Consumables',
    ysyhc: 'Consumables used',
    ztdy: 'Pause printing',
    qd: 'Start-up',
    zzdy: 'Terminate printing',

    dy: 'Printed',
    wdy: 'Unprinted',
    qrsfksdy: 'Are you sure to start printing? Before starting printing, please remove the model that was previously printed. Failure to remove it will affect the printing of the model',
    bdcg: 'Binding successful',
    bdsb: 'Binding failed',
    ybdcdyj: 'This printer has been bound',
    ztcg: 'Pause success',
    ztsb: 'Pause failure',
    jxcg: 'Continue to succeed',
    jxsb: 'Continue to fail',
    zzcg: 'Termination success',
    zzsb: 'Termination failure',

    qpwjg01: 'Slice files in total of',
    qpwjg02: '',
    sfscgqp: 'Do you want to remove this slice?',
    zwqp: 'There are no slices, you can go to the model library to add slices',
    zwsb: 'No device is available. Go to the workbench to add a device',
    sfqrztdy: 'Are you sure to pause the current printing?',
    sfqrzzdy: 'Are you sure to terminate the current printing?',
    cg: 'Floor height',
    XYbc: 'XY supplementary information',
    Zbc: 'Z compensation',
    kjc: 'Anti aliasing',
    zdsczc: 'Automatic generation support',
    zdscsyzc: 'All supports are generated automatically',
    xzqp: 'Selective slice',
  },
  historyTask: {
    breadcrumb_home: 'Home',
    breadcrumb_gzt: 'Workbench',
    breadcrumb_lsrw: 'History tasks',
    breadcrumb_rwxq: 'Details',

    rwmc: 'Task name',
    dyzz: 'Print termination',
    zzsj: 'Stop time',
    sjzl: 'Data screening',
    fdmqpwj: 'FDM slice file',
    fen: 'Copy',
    gghqpwj: 'Slice file',
    device: 'Device',
    tai: 'Copy',
    dyzsj: 'Total printing time',
    fz: 'Minutes',
    fdmhcyl: 'Consumption of FDM consumables',
    gghhcyl: 'Consumption of consumables',
    qpwj: 'Slice File',

    // 打印历史记录
    dyrw: 'Printing Task',
    sbsl: 'Device number',
    qpwjfs: 'Number of sliced files',
    ddy: 'To be printed',
    jxz: 'In progress',
    ywc: 'Completed',
    yzz: 'Termination',
    zt: 'Halt',

    dysc: 'Printing duration',
    hcsyl: 'Consumable usage',
    ck: 'Check',
    tjrw: 'Add task',
		zwdyrw: 'No print task is available',

    djckxq: 'Click for details',
  },
  vip: {
    hytc: 'VIP pack',
    yuan: 'yuan',
    month: 'months',
    ljkt: 'Activate right now',
    hyqy: 'Membership benefits',

    hl3Dmx: 'Massive 3D models',
    yqp: 'Cloud Slice',
    yckzdy: 'Remote control printing',
    ssjkdy: 'Real time monitoring printing',

    ycckj: 'Cloud storage empty question',
    mxplxz: 'Batch download of models',
    dysphf: 'Print video playback',
    yssy: 'Tube out and eliminate very much',

    jqdytq: 'Cluster printing privileges',
    qwDIYjm: 'Fun DIY modeling',
    fbczsp: 'Publish creative videos',
    kjkrzk: 'Space expansion discount',

    zskf: 'Dedicated customer service',
    VIPjm: 'VIP Model',
    VIPrw: 'VIP Tasks',
    dmxqp: 'Multi model slicing',

    ktsm: 'Opening Instructions',
    ktsm_text01: '1. You can view the content of VIP preference in the membership center. For detailed terms of service, please refer to the ',
    ktsm_text02: '"Member Service Agreement"',
    ktsm_text03: '.\n2. Within 10 minutes after the purchase is completed, the membership service will be credited to your account.In case of any network issues, please check or restart the app after 5-10 minutes.If the membership is still not credited or cannot be used, please follow the same instructions and ask for online service.\n3. The subscription options include monthly, quarterly, and yearly. The specific subscription type chosen by the user will prevail. If you activate the Baidong female service through the special activity, the service duration may vary, as indicated on the activity page.',
    gmvip: 'Purchase Member',
    content: 'Content',
    qy: 'Membership Package Benefit',
    price: 'Price',
    x20xqy: '20 benefits for you',
    zffs: 'Payment method',
    yfje: 'Payable amount',

    ktvipcg: 'Congratulations on your membership success',
  },
  // 存储空间
  storage: {
    gmkjtc: 'Purchase Space Plan',
    yuan: 'yuan',
    yj: 'Original price',
    qxzkjtc: 'Please select a space package',
    yxq01: 'Validity date',
    yxq02: 'month',
  },
  pay: {
    zffs: 'Payment method',
    alipay: 'Pay by Alipay',
    wxpay: 'WeChat Pay',
    yfje: 'Payable amount',
    yuan: 'yuan',
    ywczf: 'Completed payment',
    nhwwczf: 'You have not completed the payment',
    syt: 'Cashier',
    ddtjcgcfk: 'The order at the cashier has been submitted successfully, Only payment is needed.',
    ddzfjsts01: 'Please complete the payment within',
    ddzfjsts02: 'Otherwise, the order will be automatically retrieved',
    shxx: 'Receipt information',
    spmc: 'Product name',
    dgd: 'Wait for more',
    gmsj: 'purchase time',
    zffs: 'Payment method',
    ddje: 'Price',
    ddxq: 'Detail',
    fkcg: 'Pay successfully',
    zfwchky01: 'After paying you can',
    zfwchky02: 'View order details',
    zfwc: 'Completion of payment',
    zfts_tips: 'You are paying outside the website, please return to the current page to confirm the payment',
    cxxzzffs: 'Reselect your payment method',
    pay: 'pay',
    wwczfqzf: 'Payment not completed, please pay',
    jyygb: 'Transaction closed',
    ckzfqk: 'Check payment status',
  },
  feedback: {
    wdfk: 'My feedback',
    fknr: 'Details',
    clyj: 'Handling suggestion',
    tjsj: 'Submit time',
    type: 'Type',
  },
  shoppingCart: {
    gwc: 'Shopping cart',
    yxsp: 'Already chose goods',
    bhyf: 'Exclude freight',
    js: 'Pay',
    spxx: 'Goods info',
    sl: 'Amount',
    je: 'Money',
    cz: 'Manipulate',
    ydsclb: 'Move to the favorite list',
    qdyscsp: 'Are you sure you want to delete the product?',
    qxzyjssp: 'Please select the product to be settled',
    cgjrgwc: 'Successfully added to cart',
    zgg: 'Go on',
    qjs: 'Go check',
    sc: 'Shopping mall',
    ndgwcskd: 'Your shopping cart is still empty. Go now ',
    ggb: ' Just stroll around.',
    qxzyscsp: 'Please select the item you want to delete',
    qxzyrscjsp: 'Please select the item you want to move to your favorites',
    qx: 'Select All',
  },
  footer: {
    gywm: 'About us',
    gsjs: 'Firm info',
    jrwm: 'Join us',
    zcfw: 'Payment service',
    bzzx: 'Helping center',
    xszn: 'Novice compass',
    yjfk: 'opinions',
    yhxy: 'Customer agreement',
    fwxy: 'Service statement',
    yszc: 'Private policy',
    bqsm: 'Copyright statement',
    hyfwxy: 'Member Service Agreement',
    gsjj: 'Company Introduction',
    yxfw_cjmh: 'Serve with dedication and achieve beauty',
    feedback_tips01: 'You can directly view popular issues and feedback by visiting the',
    feedback_tips02: '「Help Center」',
    feedback_tips03: '.',
    fklx: 'Feedback type',
    content: 'Content',
    qsrfknr: 'Please enter your feedback here',
    zsxz: 'The word count is limited to 10-500 words',
    tjfk: 'Submit feedback',
    wdfk: 'My feedback',
    qxzfklx: 'Please select feedback type',
    qsr10gzfnr: 'Please enter at least 10 characters of feedback',
    ckgd: 'view more',
  },
  ...enLocale
}
export default en
