<template>
  <div class="homeIndex">
    <!-- banner -->
    <bannerView :height="430" :type="1" :list="bannerList" />
    <!-- banner end -->

    <!-- 内容 -->
    <div
      class="flex-c-c"
      style="flex-direction: column; background-color: #f2f3f5; padding-top: 75px"
    >
      <!-- 创意模型专栏 -->
      <div style="width: 1200px">
        <div class="flex-c-b">
          <!-- 标题 创意·模型专栏 -->
          <h2 class="title mb30">{{$t('home.cymxzl')}}</h2>
          <div v-if="count > 5 || true" class="flex-c mb30">
            <div class="more-logo left-logo mr50" @click="previousPage"></div>
            <div class="more-logo right-logo" @click="nextPage"></div>
            <!-- <img
              @click="previousPage"
              class="more-logo mr50"
              src="../../assets/images/left-logo.png"
              alt=""
            /> -->
            <!-- <img
              @click="nextPage"
              class="more-logo"
              src="@/assets/images/right-active-logo.png"
              alt=""
            /> -->
          </div>
        </div>
        <!-- 模型专栏 -->
        <!-- <el-row class="flex-c" :gutter="30">
          <el-col
            class="model-column"
            v-for="(item, index) of modelColumnList"
            :key="index"
            :span="5"
          >
            <el-image class="model-column-img" :src="item.fileUrl" fit="none" />
            <div class="flex-c-c model-column-name">
              <div class="textOver1">{{ item.name }}</div>
            </div>
          </el-col>
        </el-row> -->
        <el-carousel ref="modelColumn" height="300px" indicator-position="none" :autoplay="false" arrow="never">
          <el-carousel-item v-for="(item, index) in modelColumnList" :key="index">
            <el-row class="flex-c" :gutter="30">
              <el-col class="model-column" v-for="(son, index) of item" :key="index" :span="8">
                <div class="column_item" @click="goColumnDetail(son)">
                  <el-image class="model-column-img" :src="son.columnPicPath" fit="cover" />
                  <div class="model-column-name">
                    <div class="textOver1">{{ son.columnName }}</div>
                  </div>
                </div>
                
              </el-col>
            </el-row>
          </el-carousel-item>
        </el-carousel>
        <!-- 更多专栏 -->
        <div class="more-column flex-c-c" @click="$router.push('/modelColumn')">{{$t('home.gdzl')}}>></div>
      </div>
      <!-- 创意模型专栏 end -->

      <!-- 海量·3D模型 -->
      <div style="width: 1200px" class="mt90">
        <!-- 标题 -->
        <div class="flex-c-b mb10">
          <!-- 海量·3D模型 -->
          <h2 class="title">{{$t('home.hl3Dmx')}}</h2>
          <div class="flex-c Model3D">
            <div
              v-for="(item, index) of navList"
              :key="index"
              class="ml55 nav-item"
              :class="{ active: navActive == item.id }"
              @click="handleNav(item)"
            >
              {{item.name}}
            </div>
          </div>
        </div>

        <!-- 内容 -->
        <div style="min-height: 600px;">
          <Waterfall
            :key="navActive"
            :imageData.sync="listData"
            :columnCount="5"
            :columnGap="31"
            :totalWidth="1200"
            backgroundColor="#F2F3F5"
            bgColor="#fff"
          ></Waterfall>
          <NoMore v-if="noMore"></NoMore>
          <!-- 暂无模型 -->
          <el-empty v-if="!listData.length" :description="$t('common.zwmx')"></el-empty>
        </div>
      </div>
      <!-- 海量·3D模型 -->
    </div>
    <!-- 内容 end-->
  </div>
</template>
<script>
// @ is an alias to /src
import headerView from "@/components/header.vue";
import bannerView from "@/components/banner.vue";
import Waterfall from "@/components/Waterfall";
import NoMore from "@/components/NoMore.vue";

import {mapState} from 'vuex';

export default {
  name: "homeIndex",
  components: {
    headerView,
    bannerView,
    Waterfall,
    NoMore,
  },
  computed: {
    ...mapState({
      toekn: state => state.token,
      searchKey: state => state.search.searchKey,
      search: state => state.search.searchText,
    })
  },
  watch: {
    searchKey(val) {
      console.log(' ');
      console.log('点击了搜索key', val);
      console.log('点击了搜索', this.search);
      this.page = 1;
      this.getList();
    }
  },
  data() {
    return {
      bannerList: [],
      modelColumnList: [],
      count: 7, //创意模型专栏数量
      modelColumnPage: 1,

      listData: [], //模型列表
      page: 1,
      listLoadin: false, // 防止滚动到底部后重复触发下拉加载
      noMore: false,
      // navList: ["精选", "最新", "最热", "更多"],
      navList: [
        { id: 1, name: this.$t("home.tj") }, // 精选
        { id: 2, name: this.$t("home.zx") }, // 最新
        { id: 3, name: this.$t("home.zr") }, // 最热
        { id: 4, name: this.$t("home.gd") }, // 更多
      ],
      navActive: 1,
    };
  },
  created() {},
  mounted() {
    // 监听页面滚动
    const that = this;
    window.addEventListener("scroll", that.onTableScroll);
    that.getBanner();
    that.getZhuanLan();

    this.page = 1;
    this.listData = [];
    that.getList();
  },
  beforeDestroy() {
    // 移除监听事件
    window.removeEventListener("scroll", this.onTableScroll);
  },
  methods: {
    //上一页
    previousPage() {
      this.$refs.modelColumn.next();
    },
    //下一页
    async nextPage() {
      this.$refs.modelColumn.next();
    },

    // 获取banner
    getBanner() {
      let params = {
        bannerType: 18,//12 pc专栏广告位， 15 pc模型广告位  16首页产品库(商城)广告位 17 购买会员广告位 18 首页广告位
        pageNum: 1,
        pageSize: 99,
      };
      this.http(this.api.getListByType, params, "post").then((res)=>{
        console.log("首页模型专栏banner:", res);
        if (res["code"] == 200) {
          this.bannerList = res.rows || [];
        }
      });
    },

    // 获取专栏
    async getZhuanLan() {
     
      let params = {
        pageNum: this.modelColumnPage,
        pageSize: 99,
      };
      let res = await this.http(this.api.columnList, params, 'post', { Authorization: this.token });
      if (res.code == 200) {
        let listParent = [];
        let listChild = [];
        res.rows.forEach((item, index) => {
          if (listChild.length < 2) {
            listChild.push(item);
            if (index == (res.rows.length - 1)) {
              listParent.push(listChild);
            }
          } else {
            listParent.push(listChild);

            listChild.push(item);
            listChild = []
          }
        });
        this.modelColumnList = listParent;
        this.count = res.total;
      } else {
        this.$message.error(res.msg);
      }
    },

    /// 获取广告内容
    async getListByType(bannerType) {
      let params = {
        bannerType: bannerType,
        pageNum: 1,
        pageSize: 99,
      };
      return await this.http(this.api.getListByType, params, "post");
    },

    // 获取模型列表
    getList() {
      let params = {
        pageNum: this.page,
        pageSize: 10,
      };
      
      // 是否精选(1:精选，0:不精选)
      if (this.navActive == 1) params["recommendFlag"] = this.navActive; // 是否精选
      params['search'] = this.search;
      // 0 浏览量升序， 1 浏览量降序，2 收藏数升序，3 收藏数降序，4 发布时间升序， 5 发布时间降序 
      if (this.navActive == 2) params['orderByType'] = 5;
      if (this.navActive == 3) params['orderByType'] = 3;
      this.http(this.api.getModelList, params, "post").then((res) => {
        this.listLoadin = false;
        if (res.code == 200) {
          if (this.page == 1) {
            this.listData = res.rows;
          } else if (res.rows.length > 0) {
            this.listData = this.listData.concat(res.rows);
          } else {
            this.noMore = true;
            return;
          }
          this.page++;
        } else {
          // this.$message.error(res.msg);
        }
      });
    },

    // 监听页面滚动
    onTableScroll() {
      // 页面滚动长度
      var scrollTop =  document.documentElement.scrollTop || document.body.scrollTop;
      // 浏览器视窗高度
      var clientHeight =  document.documentElement.clientHeight || document.body.clientHeight;
      // 页面总长度
      var scrollHeight =  document.documentElement.scrollHeight || document.body.scrollHeight;
      // 浏览器内容超出视窗范围的高度
      var invisibleHeight = scrollHeight - clientHeight;
      // 浏览器滚动到底部的距离
      var offBottom = invisibleHeight - scrollTop;
      // console.log('offBottom>>>>>>>>>>>>>>>', offBottom, invisibleHeight, scrollTop);
      if (offBottom <= 0) {
        if (this.listLoadin) return;
        this.listLoadin = true;
        this.getList();
      }
      // if (offBottom > 0) this.listLoadin = false;
    },

    handleNav(item) {
      if(item.id == 4) {
        this.$router.push('/modelLib');
        return;
      }
      this.navActive = item.id
      this.page = 1;
      this.listData = [];
      this.getList();
    },
    
    //跳转专栏详情
    goColumnDetail(item){
      this.$router.push({
        path: '/columnDetail',
        query: {
          columnId: item.columnId
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
/* 模型专栏 */
.title {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
}
.more-logo {
  width: 12px;
  height: 20px;
  cursor: pointer;

  &.left-logo {
    background: url('../../assets/images/left-logo.png') no-repeat;

    &:hover {
      background: url('../../assets/images/left-active-logo.png') no-repeat;
    }
  }

  &.right-logo {
    background: url('../../assets/images/right-logo.png') no-repeat;

    &:hover {
      background: url('../../assets/images/right-active-logo.png') no-repeat;
    }
  }
}
.model-column {
  position: relative;
  
  .column_item{
    cursor: pointer;
    border-radius: 16px;
    overflow: hidden;
    background: #fff;
  }
  
  .model-column-img {
    display: block;
    width: 100%;
    height: 207px;
  }

  .model-column-name {
    // width: 92%;
    // height: 40px !important;
    // background: rgba(0, 0, 0, 0.3);
    // border-radius: 8px;
    // position: relative;
    // bottom: 50px;
    // left: 50%;
    // transform: translate(-50%);
    // z-index: 100;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding: 20px 10px;
  }
}

// 更多专栏
.more-column {
  width: 200px;
  height: 40px;
  background: rgba(240, 240, 240, 0);
  border: 1px solid #666666;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin: auto;
  cursor: pointer;
}
/* 模型专栏 end */
.nav-item {
  cursor: pointer;
}
.nav-item.active {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
</style>
