export default {
  state: {
    userInfo: {},
  },
  getters: {
  },
  mutations: {
    SET_USER_INFO(state, data) {
      // 变更状态
      state.userInfo = data;
    }
  },
  actions: {
    setUserInfo({ commit }, data) {
      commit('SET_USER_INFO', data);
    }
  },
  namespaced: true,
}
