<template>
  <div>
    <el-carousel :height="height+'px'" :loop="true">
      <el-carousel-item v-for="(item, _) of list" :key="_">
        <el-image v-if="type == 1" class="banner-img" :src="item.fileUrl" fit="cover" @click="goUrl(item)"></el-image>
        <el-image v-if="type == 2" class="banner-img" :src="item" fit="cover"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    list: [],
    height: 0,
    type: {
      type: Number,
      default: 1,
    },
  },
  name: "banner",
  data() {
    return {};
  },
  methods:{
    goUrl(data){
      console.log(data.jumpType);
      //banner跳转 jumpType 0 跳转链接 1 专栏 2 模型 3 商品 5 无跳转
      if(data.jumpType === 0){
        if(data.jumpUrl){
          location.href = data.jumpUrl;
        }
      }else if(data.jumpType == 1){
        if(data.bindingId){
          this.$router.push({path: '/columnDetail', query: {columnId: data.bindingId}})
        }
      }else if(data.jumpType == 2){
        if(data.bindingId){
          this.$router.push({path: `/modelDetail/${data.bindingId}`})
        }
      }else if(data.jumpType == 3){
        console.log(data.bindingId);
        if(data.bindingId){
          this.$router.push({ name: "productDetail", query: { id:data.bindingId } });
        }
      }else if(data.jumpType == 5){
        
      }
    }
  }
};
</script>

<style>
.banner-img {
  width: 100%;
  height: 100%;
}
</style>
