import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=3ab93b21&scoped=true"
import script from "./footer.vue?vue&type=script&lang=js"
export * from "./footer.vue?vue&type=script&lang=js"
import style0 from "./footer.vue?vue&type=style&index=0&id=3ab93b21&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab93b21",
  null
  
)

/* custom blocks */
import block0 from "./footer.vue?vue&type=custom&index=0&blockType=div&class=flex-c-b"
if (typeof block0 === 'function') block0(component)
import block1 from "./footer.vue?vue&type=custom&index=1&blockType=div&class=Copyright-box&style=margin-top%3A%205px%3B"
if (typeof block1 === 'function') block1(component)

export default component.exports