

const ja = {
  //打印任务详情
  printTaskDetail: {
    printDetail: '印刷の詳細',
    printPreview: '印刷のプレビュー',
    videoMonit: 'ビデオモニタリング',
    noCamera: 'カメラなし',
    printPars: '印刷パラメータ',
    layThick: 'レイヤーの厚さ',
    singLayExpTime: '単層露光時間',
    bottomLayExpTime: '底層露光時間',
    expDelay: '露光遅延',
    bottomLayExpLay: '底層露光レイヤー数',
    bottomLayExpTranLay: '底層露光過渡レイヤー数',
    liftHeight1: 'リフト高さ1',
    lifgSpeed1: 'リフト速度1',
    liftHeight2: 'リフト高さ2',
    lifgSpeed2: 'リフト速度2',
    lowerSpeed: '降下速度',
    zAxLiftAfterPrint: '印刷完了後のZ軸リフト',
    continuePrint: '印刷を続ける',
    continuePrinting: '回復中です',
  },
  //公共的
  common: {
    all: 'すべて',
    download: 'ダウンロード',
    loading: '読み込み中',
    noData: 'データなし',
    addImg: '画像を追加',
    zzsc: 'アップロード中',
    submit: '提出',
    cancel: 'キャンセル',
    submit_success: '提出成功',
    confirm: '確認',
    qxz: '選択してください',
    qsr: '入力してください',
    search: '検索',
    delete: '削除',
    hint: 'ヒント',
    home: 'ホーム',
    zztj: '提出中',
    noMore: 'これ以上ありません',
    deleting: '削除中',
    time: '時間',
    imgFormatErr: '画像形式は',
    czcg: '操作成功',
    save: '保存',
    ckxq: '詳細を表示',
    shouqi: '折りたたむ',
    zhankai: '展開',
    zwmx: 'モデルなし',
    zwsp: '商品なし',
    weChat: 'WeChat',
    zyhydd: '章魚核モバイル',
  },
  header: {
    fileUpload: 'ファイルアップロード',
    uploadModel: 'モデルのアップロード',
    workbench: 'ワークベンチ',
    shoppingCart: 'ショッピングカート',
    day: '日',
    register: '登録',
    login: 'ログイン',
    modelBase: 'モデルライブラリ',
    modelColumn: 'モデルコラム',
    productLibrary: 'ショッピングセンター',
    searchPlaceholder: '興味のあるコンテンツを検索する',
    becomeDue: '期限切れ',
    renew: '更新',
    index: 'ホーム',
    bdsj: '携帯電話をバインド',
    bd: 'バインド',
    smdl: 'QRコードでログイン',
    qsy: 'お使いください',
    smewm: 'QRコードをスキャン',

  },

  login: {
    title: '章魚核アカウントにログイン',
    myzhmfzc: 'アカウントがありませんか？無料で登録',
    sjyzmdl: '携帯電話の認証コードでログイン',
    yxyzmdl: 'メールの認証コードでログイン',
    zhmmdl: 'アカウントとパスワードでログイン',
    qsrsjh: '携帯番号を入力してください',
    qsryx: 'メールアドレスを入力してください',
    qsrzqyx: '正しいメールアドレスを入力してください',
    qsrsjhOryx: '携帯番号またはメールアドレスを入力してください',
    qsrzqsjh: '正しい携帯番号またはメールアドレスを入力してください',
    qsrzqsjh02: '正しい携帯番号の入力をお願いします',
    qsryzm: '認証コードを入力してください',
    qsrmm: 'パスワードを入力してください',
    hqyzm: '認証コードを取得',
    fsz: '送信中',
    khq: 'S後に取得可能',
    tyysxy: 'ユーザー規約とプライバシーポリシーに同意してください',
    grzx: '個人センター',
    tcdl: 'ログアウト',
    smcg: 'スキャン成功',
    qzsjsqr: '携帯で確認してください',
    xczddl: '次回から自動ログイン',
    loginText: 'ログイン',
    agreementText01: '私は章魚核の',
    agreementText02: '《利用規約》',
    agreementText03: 'および',
    agreementText04: '《プライバシーポリシー》',
    otherLogin: '他のログイン方法',
    wxLogin: 'WeChatでログイン',
    qqLogin: 'QQでログイン',
    weiboLogin: 'Weiboでログイン',
    srsjhOryx: '携帯番号またはメールアドレスを入力',
    srndmm: 'パスワードを入力（6〜18桁の文字）',
    wjmm: 'パスワードを忘れた場合',
    qdtcm: 'ログアウトしてもよろしいですか？ 続行しますか？'
  },
  home: {
    tj: 'おすすめ',
    zx: '最新',
    zr: '人気',
    gd: 'もっと見る',
    zhpx: '総合順',
    cymxzl: 'クリエイティブ・モデルコラム',
    hl3Dmx: '豊富な3Dモデル',
    gdzl: 'その他のコラム',
  },
  zhuanLan: {
    title: 'すべてのモデルコラム',
    noZhuanLan: '専門コラムはまだありません',
  },
  // 模型详情
  modelDetail: {
    scy: 'アップロード日時',
    zyb: '章魚币',
    fl: 'カテゴリ',
    mf: '無料',
    collect_success: 'お気に入りに追加しました',
    collect_cancel: 'お気に入りを解除しました',
    dbxz: '一括ダウンロード',
    plxz: '一括ダウンロード',
    yjqp: '一括スライス',
    mxms: 'モデルの説明',
    hyzx: '会員専用',
    hyzx_desc: '会員にアップグレードして、無制限のモデルを自由にダウンロード。',
    ktvip: '会員になる',
    wj: 'ファイル',
    spxg: '実物撮影エフェクト',
    pl: 'コメント',
    qpg01: '共',
    qpg02: 'つのモデルファイル',
    gsp: 'つの実物撮影',
    tpl: '件のコメント',
    yl: 'プレビュー',
    qp: 'スライス',
    download: 'ダウンロード',
    fsp: '実物撮影を投稿',
    fbspxg: '実物撮影エフェクトを投稿',
    sctp: '写真をアップロード',
    qscsptp: '実物撮影の写真をアップロードしてください',
    zan: 'いいね',
    huifu: '返信',
    xpl: 'コメントを書く',
    placeholder1: '何か言ってください',
    placeholder2: '内容',
    emoji: '絵文字',
    img: '画像',
    qsrplnr: 'コメント内容を入力してください',
    ljgm: '今すぐ購入',
    buy: '購入',
    zybbz: '章魚币が不足しています。充電に進む',
    yuan: '元',
    qxzzybtc: '章魚币パッケージを選択してください',
    gmcg: '購入成功',
    tjmx: 'おすすめモデル',
    hyh: 'ランダム',
    ydcc: 'クラウドストレージ',
    bdcc: 'ローカルストレージ',
    xzcg: 'ダウンロード成功',
    qxzxzwj: 'ダウンロードするファイルを選択してください',
    qxzqpwj: 'スライスファイルを選択します',
    sccg: '削除成功',
    qdscmx: 'このモデルを削除しますか？',
    ljqp: '今すぐスライス',
  },


  // 模型发布
  modelPublish: {
    title: 'モデルのアップロード',
    mxly: 'モデルのソース',
    ycmx: 'オリジナルモデル',
    fxmx: 'モデルの共有',
    ecczmx: '二次創作モデル',
    bqsm: '著作権の明示',
    xzgxxy: 'クリエイティブ・コモンズ・ライセンスを選択してください',
    dj: '価格設定',
    sf: '有料',
    mf: '無料',
    srzybjg: '章魚幣の価格を入力してください',
    yczm: 'オリジナル証明',
    yczm_tips: '「作品登録証明書」または他のオリジナル証明書をアップロードすると、モデルは優先的に審査され、精選されます！',
    sczm: '証明書をアップロード',
    scmx: 'モデルをアップロード',
    add: '追加',
    file_tips01: '最大500MBのファイルをアップロードできます',
    file_tips02: 'サポートされているモデルファイル形式：',
    file_type_err: 'アップロードされたファイルの形式が正しくありません',
    mxmc: 'モデルの名前',
    mxmc_placeholder: '60文字以内で入力してください',
    mxlb: 'モデルのカテゴリ',
    mxlb_placeholder: 'モデルのカテゴリを選択してください',
    yssz: 'プライバシー設定',
    gk: '公開',
    zy: 'プライベート',
    img_list: '画像一覧',
    img_list_tips: '最大10枚までアップロード可能',
    sctj: '画像一覧をアップロード',
    qsctj: '画像一覧をアップロードしてください',
    desc: '説明',
    desc_placeholder: 'このモデルについて簡単に説明してください...',
    ck: '詳細を見る',
    qxzmxly: 'モデルのソースを選択してください',
    qscmxzm: 'モデルの証明書をアップロードしてください',
    qscmxwj: 'モデルファイルをアップロードしてください',
    qscmxwjslt: 'モデルファイルのサムネイルをアップロードしてください',
    scwc: 'アップロード完了',
    scsb: 'アップロード失敗',
    cckjbz: 'ストレージ容量が不足しています。個人センターでストレージ容量を拡張できます',

		uploadModelCloseMsg: 'アップロードモデルから脱退しますか?',
  },

  //商城
  goodsList: {
    allClass: 'すべてのカテゴリ',
    qxsc: 'お気に入り解除',
    sc_goods: 'お気に入り',
    yxzsp: '選択済商品',
    jrgwc: 'カートに追加',
    cwdshdzxz: '配送先を選択',
    tjdz: '住所を追加',
    qxzdz: '住所を選択',
    spxq: '商品詳細',
    sppj: '商品評価',
    kf: 'カスタマーサポート',
    rmjx: '人気の特選品',
    jrpp: 'ブランドに入る',
    sort: 'ソート',
    zh: '総合',
    xl: '販売数',
    price: '価格',
    hpd: '評価',
    zhpj: '総合評価',
    leibie: 'カテゴリ',
    fenlei: '分類',
    rfk: '人が支払っています',
    ps: '発送',
    fh: '発送元',
    yf: '送料',
    myf: '送料無料',
    zwpj: '評価がありません',
    rsc: '人がお気に入り',
    qi: '起',
    qdscgdz: 'この住所を削除しますか？',
    kcbz: '在庫切れ',
    xtqhsjxxyz: 'オプション、商家と相談の上でご記入ください。支払い後に商家が確認できます',
    qxzgg: 'オプションを選択',
    zwcp: '商品がありません',
    ksp: 'アイテム',
    zhuanqu: '専門区域',
  },


  user: {
    qsctx: 'プロフィール写真をアップロードしてください',
    qsrnc: 'ニックネームを入力してください',
    qsrbq: 'タグを入力してください',
    changePwdTitle: 'パスワードの変更',
    changePwdTips: 'アカウントの安全性を確保するため、携帯電話に確認コードを送信します：',
    sendYzm: '確認コードを送信',
    reSendYzm: '再送信',
    sendCodeSuccessful: '確認コードが送信されました',
    inputCode: '確認コードを入力',
    inputOldPwd: '現在のパスワードを入力してください',
    inputNewPwd: '新しいパスワードを入力してください',
    inputNewPwdAgain: '新しいパスワードを再入力してください',
    pwdDifferent: 'パスワードが一致しません、再入力してください',
    unableSetPwd: '電話番号がバインドされていないため、パスワードを設定できません',
    pwdLengthTips: 'パスワードは6～20文字の英字、数字、または記号で構成されています',
    tips: 'お知らせ',
    cancelAccountTips: 'アカウントを削除すると、すべてのデータが消去され、元に戻すことはできません。アカウントを削除しますか？',
    unbindingWxTips: 'WeChatのバインドを解除しますか？',
    unbindingQqTips: 'QQのバインドを解除しますか？',
    unbindingWbTips: 'Weiboのバインドを解除しますか？',
    confirm: '確認',
    cancel: 'キャンセル',
    cancelAccount: 'アカウントの削除',
    cancelAccountText: 'アカウントを削除すると、すべてのデータが消去され、元に戻すことはできません。慎重に操作してください。',
    bindPageTitle: 'アカウントのバインド',
    currentEmail: '現在のメールアドレス',
    currentMobile: '現在の携帯電話番号',
    change: '変更',
    thirdPartTitle: 'サードパーティアカウントのバインド',
    thirdPartNotes: 'サードパーティアカウントをバインドすると、章魚核に直接ログインできます。現在のアカウントが対応するサイトでログイン中の場合は、ログアウトしてから再度バインドする必要があります。',
    binding: 'バインド',
    unbinding: 'バインドを解除',
    submit: '提出',
    inputNewMobile: '新しい携帯電話番号を入力してください',
    mobileFormat: '携帯電話番号の形式が正しくありません',
    changeEmailTips: 'アカウントの安全性を確保するため、確認コードをメールアドレスに送信します：',
    inputNewEmail: '新しいメールアドレスを入力してください',
    inputEmailCode: '新しいメールアドレスの確認コードを入力してください',
    emailFormat: 'メールアドレスの形式が正しくありません',
    emailRepeat: '新しいメールアドレスは元のメールアドレスと同じであってはなりません',
    emailVerified: '確認済み',
    storageRule: 'ストレージスペースの規則',
    storageTitle: '無料で5GBの専用スペース',
    storageTime: '永久に有効',
    rule: '規則',
    freeSpace: '利用可能なスペース',
    qpcc: 'スライスサイズ',
    qpdx: 'スライスサイズ',
    qpcs: 'スライスの数',
    tier: '層',
    jx: 'モデル',
    xq: '詳細',
    grzx: '個人センター',
    grzl: '個人情報',
    grsz: '個人設定',
    sctx: 'プロフィール写真をアップロード',
    nickname: 'ニックネーム',
    qsr_nickname: 'ニックネームを入力してください',
    bq: '署名',
    hdqmhrgdrkjno: '良い署名は他の人にも見てもらえます',
    yzsjh: '携帯電話を確認',
    ghsjh: '携帯電話を変更',
    bdsjh: '携帯電話をバインド',
    szzh: 'アカウントを設定',
    xgmm: 'パスワードの変更',
    yzyx: 'メールアドレスの確認',
    bdyx: 'メールアドレスをバインド',
    ghyx: 'メールアドレスを変更',
    yykj: '使用済みスペース：',
    dq: '期限切れ',
    qp_cancel: 'キャンセルが成功しました',
    wddd: '私の注文',
    wdmx: '私のモデル',
    wdqp: '私のスライス',
    wdsc: '私のお気に入り',
    wdpj: '商品注文評価',
    mx: 'モデル',
    ssmxmc: 'モデル名を検索',
    scmx: 'モデルをアップロード',
    cckj: 'ストレージスペース',
    zh: 'アカウント',
    copy: 'コピー',
    zyb: '章魚幣',
    ycc: 'クラウドストレージ',
    qsrsjh: '携帯電話番号を入力してください',
    wdzyb: '私の章魚幣',
    qpzt: 'スライス状態',
    qpwxy: 'スライスが応答中です。お待ちください。',
    yqx: 'キャンセル済み',
    cczjqxqprw: 'この操作はスライスタスクをキャンセルします。続行しますか？',
    zwqpsj: 'スライスデータがありません',
    hp: '良い評価',
    cp: '悪い評価',
    zp: '普通の評価',
    content: '内容',
    img: '画像',
    priority: '優先度',
    vipyxpdz: 'VIP優先待ち行列中',
    lxkf: 'カスタマーサービスに連絡',
    gzsj: '営業時間',
    txyj: '意見を書く',
    qpsb: 'スライス失敗',
    bkqx: 'キャンセル不可',
    ckqb: 'すべてを表示',
    zwmxwjksc: '削除可能なモデルファイルはありません',
    sctx_size: 'プロフィール写真のサイズは2MBを超えることはできません',
    modelsize: 'サイズ',
    xzsj: 'ダウンロード時間',
    smbd: 'QRコードをスキャンしてバインド',
    qsywxsm: 'QRコードをスキャンしてください',
    crcckj: 'ストレージスペースを拡張',
    kthyxgdkj: 'VIPになってより大きなスペースを利用できます',
    chongZhi: 'チャージ',
    qbmx: 'ウォレット明細',
    yxz: 'ダウンロード済み',
    wqp: "未スライス",
    yqp: "スライス済み",
    scdycc: "クラウドストレージにアップロード",
    ysc: "アップロード済み",
    cczjscmxwj: "この操作はモデルのすべてのファイルを削除します",
    sfjx: "続行しますか？",
    cczjscmxwj02: "この操作はモデルファイルを削除します",
    cczjscmxwj03: "この操作はモデルのアップロード履歴を削除します",
    yjpdsj: "予想待ち行列時間",
    cxqp: "スライスを再開",
    addTime: "追加時間",
    scqprw: "この操作はスライスタスクを削除します",
    qxqprw: "この操作はスライスタスクをキャンセルします",
    merchant: "商家",
    zwsj: '商家がいません',
    zwpjsj: '評価データがありません',
    tpbncg: '写真は',
    tpzdsc: '最大で9枚しかアップロードできません',
    pjcg: '評価成功',
    fbpjz: 'コメント中',
    user_yqp: "クラウドスライス",
    user_rjqp: "ソフトウェアスライス",
    user_cxyh: "最適化を再開",
    user_cxyh_tips: "最適化は正常に開始されました。完了をお待ちください。",
  },


  orders: {
    qbdd: 'すべての注文',
    j6yndd: '最近6ヶ月の注文',
    sixyqdd: '6ヶ月前の注文',
    sp: '商品',
    sl: '数量',
    sjzf: '実際の支払い',
    ly: 'メッセージ',
    zt: 'ステータス',
    jycz: 'トランザクション操作',
    ddh: '注文番号',
    ddh02: '注文番号',
    none: 'なし',
    dzf: '未払い',
    jygb: '取引が閉じられました',
    dfh: '発送待ち',
    dsh: '受け取り待ち',
    dpj: '評価待ち',
    ywc: '完了しました',
    tksh: '返金/アフターサービス',
    pdz: '待機中',
    qpz: 'スライス中',
    qfk: '支払いに進む',
    qxdd: '注文をキャンセル',
    xgdz: '住所を変更',
    shdz: '配送先住所',
    shou: '受け取る',
    syxdz: '新しい住所を使用する',
    xgbdz: 'この住所を変更',
    qdxg: '変更を確認',
    ddxq: '注文詳細',
    qxyy: 'キャンセル理由',
    qxzqxyy: 'キャンセル理由を選択してください',
    wxts: 'ご注意',
    ddcgqxhwfhf: '注文をキャンセルすると、元に戻すことはできません',
    ddcgqxhwfhf02: '注文が正常にキャンセルされると、支払い済みの金額は5営業日以内に返金されます',
    zbqx: '今はキャンセルしない',
    qdqx: 'キャンセルを確認',
    qxzqxyy: 'キャンセル理由を選択してください',
    qxcg: 'キャンセルが成功しました',
    qxz: 'キャンセル中',
    dd_tips01: 'お支払いは',
    dd_tips02: '内に完了してください。',
    minutes: '分',
    seconds: '秒',
    tjdd: '注文を確定',
    fkcg: '支払いが成功しました',
    sjfh: '出荷された商品',
    qrsh: '受け取りを確認',
    ddxx: '注文情報',
    mjly: 'バイヤーメッセージ',
    tjsj: '提出時間',
    spxq: '商品詳細',
    cz: '操作',
    spzj: '商品合計',
    yf: '送料',
    kd: '配送',
    fksj: '支払い時間',
    fhsj: '発送時間',
    shsj: '受け取り時間',
    wcsj: '完了時間',
    qxsj: 'キャンセル時間',
    pg: 'パッケージ',
    ydh: '荷物番号',
    ckwl: '追跡を確認',
    tkcg: '返金が成功しました',
    sjyty: '店舗が同意しました',
    ddsjsh: '店舗が商品を受け取るのを待っています',
    cxsq: '申請を取り消す',
    tkshxq: '返金/アフターサービスの詳細',
    mjsqtk: 'バイヤーが返金を申請しました',
    mjclthsq: '店舗が返品申請を処理中',
    tkwb: '返金が完了しました',
    tkxq: '返金詳細',
    cjsj: '取引時間',
    unitPrice: '単価',
    youFei: '送料',
    tkbh: '返金番号',
    tkje: '返金金額',
    sqjs: '申請数',
    yuan_yin: '原因',
    yao_qiu: '要求',
    shuo_ming: '説明',
    mjchsq: '購入者が申請を取り消しました',
    sjjjtk: '商人が返金を拒否しました',
    jjyy: '拒否の理由',
    mjth: '購入者が商品を返品しました',
    scdd: '注文を削除',
    qdyscgddm: 'この注文を削除してもよろしいですか？',
    tkdzqx01: '返金は',
    tkdzqx02: '営業日後にアカウントに返金されます',
    qrdd: '注文を確認',
    swmrdz: 'デフォルトの住所に設定',
    mrdz: 'デフォルトの住所',
    gmjly: '売り手へのメッセージ',
    xzshdz: '新しい配送先住所',
    tjdz: '住所を追加',
    bjdz: '住所を編集',
    shr: '受取人',
    sjhm: '電話番号',
    xzssq: '省/市/区/町を選択',
    xzgjdq: '国/地域を選択',
    bcbsy: '保存して使用',
    qsrshrmc: '受取人の名前を入力してください',
    qsrshrdh: '受取人の電話番号を入力してください',
    qxzshdz: '配送先住所を選択してください',
    qsrshrxxdz: '受取人の詳しい住所を入力してください',
    szcg: '設定が成功しました',
    jsz: '発送先',
    hyf: '送料込み',
    bhyf: '送料別',
    spzt: '商品の状態',
    qxztklx: '返金の種類を選択してください',
    qxztkyy: '返金理由を選択してください',
    qxzkdgs: '配送会社を選択してください',
    qtxwldh: '追跡番号を入力してください',
    xgcg: '変更が成功しました',
    xgsb: '変更に失敗しました',
    sqcg: '申請が成功しました',
    shcg: '受取が成功しました',
    tkpz: '返金証明',
    cfh: '発送を促進',
    qrsh: '受取を確認',
    ckwl: '配送状況を確認',
    qpj: '評価に行く',
    shdh: '售后单号',
    wsh: '未售後',
    shz: '售後中',
    shwc: '售後完了',
    yjj: '既に拒否',
    tkthcg: '返金・返品が成功しました',
    dcl: '処理中',
    ddhj: '発送待ち',
    jjsq: '申請を拒否',
    lxpt: 'サポートに連絡',
    reapply: '再申請',
    sqtk: '返金を申請',
    tklx: '返金の種類',
    xztklx: '返金の種類を選択',
    tkyy: '返金理由',
    xztkyy: '返金理由を選択',
    tksm: '返金の説明',
    qsrtkyy: '返金理由を入力してください',
    srjdhspm: '街道、町、または商品名を入力してください',
    lrsztayg: '例：深圳 天安云谷',
    nytjtksqddcl: '返金申請が提出されました、商人の対応をお待ちください',
    tytk: '返金に同意',
    tytk_tips011: '商人が返金に同意しました。詳細は',
    tytk_tips012: 'をご覧ください',
    tytk_tips02: '返金商品は発送され、商人が受け取った後、返金手続きが迅速に行われます',
    tytk_tips03: '商人が返金申請を受け入れ、返金金額が元に戻されました',
    tytk_tips04: '商人が返金申請を拒否しました',
    tytk_tips05: '返金申請を取り消しました。新たに返金申請を行うことができます',
    thdz: '返品先住所',
    txwldh: '追跡番号を記入',
    order_qrshddts: "注文内に售後中の商品がある場合、自動的に返金申請がキャンセルされます。受取を確認しますか？",
    kddh: "追跡番号",
    kdgs: "配送会社",
    jtk: "返金のみです",
    thtk: "返品と返金",
    jihui: "戻す",
    qsrkddh: "追跡番号を入力してください",
    sfcxgshdd: "この售後注文を取り消しますか？",
    cxz: "キャンセル中",
    gn: '国内',
    hw: '海外',
  },

  // 评价订单
  commentOrder: {
    pjdd: '注文を評価する',
    sppf: '商品評価',
    pjsd: '評価と晒し物',
    fxtyxd_content: '体験した感想をシェアして、購入を考えている他の人に参考になる情報を提供しましょう~',
    zdksc: '最大9枚までアップロード可能です。各画像のサイズは10MB以下にしてください',
    fbpl: 'コメントを投稿',
    plnrbnwk: 'コメント内容は空白にできません',
  },

  // 物流
  logistics: {
    gsp: '商品'
  },
  other: {
    joinTip: '個人ユーザーはオンラインカスタマーサービスを通じて迅速にお問い合わせいただけます。また、以下の方法でお問い合わせいただけます:'
  },

  // 工作台
  workbenches: {
    startPrint: '印刷を開始',
    paused: '一時停止',
    starting: '開始中',
    abortingPrinting: '印刷中止中',
    stopTaskDialog: {
      tip: 'ヒント',
      txt: '印刷を中止しますか？',
      confirm: '確認',
      cancel: 'キャンセル'
    },
    unBindingSuc: '正常に解除されました',
    unBindingFail: '解除に失敗しました',
    tip: 'ヒント',
    unBindingTxt: 'このデバイスのバインドを解除しますか？',
    cancel: 'キャンセル',
    confirm: '確認',
    gzt: '作業台',
    kx: '利用可能',
    jxz: '進行中',
    zt: '一時停止',
    ywc: '完了',
    dkj: 'オフライン',
    gz: '障害',
    wwc: '未完了',
    zz: '中止',
    copy: 'コピー',
    copy_success: 'コピー成功',
    dysb: '印刷デバイス',
    dyrw: '印刷タスク',
    lsrw: '過去のタスク',
    cjdyrw: '印刷タスクを作成',
    sbzt: 'デバイスの状態',
    jx: 'モデル',
    sssbmc: 'デバイス名またはIDを検索',
    tjsb: 'デバイスを追加',
    dycs: '印刷層数',
    ssrwmc: 'タスク名を検索',
    sbmc: 'デバイス名',
    qsrsbmc: 'デバイス名を入力してください',
    qsrsbid: 'デバイスIDを入力してください',
    // タスク作成
    rwmc: 'タスク名',
    rwmcbnwk: 'タスク名は必須です',
    yxqp: '選択されたスライス',
    cc: 'サイズ',
    dysc: '印刷時間',
    xzsb: 'デバイスを選択',
    qxz: '選択してください',
    tjqp: 'スライスを追加',
    xyb: '次へ',
    qsrrwmc: 'タスク名を入力してください',
    qtjqp: 'スライスを追加してください',
    qxzdysb: '印刷デバイスを選択してください',
    ddy: '印刷待機',
    dyz: '印刷中',
    gzz: '作業中',
		ygb: '中止済み',
		csz: '伝送です',

    dyhs: '印刷時間',
    dyjd: '印刷進捗',
    gghqpwj: 'スライスファイル',
    fen: '回',
    device: 'デバイス',
    tai: '台',
    dyzsc: '印刷総時間',
    fz: '分',
    gghhcyl: '材料使用量',
    ydysj: '印刷済み時間',
    sysj: '残り時間',
    yjsyhc: '予想使用材料',
    ysyhc: '使用済み材料',
    ztdy: '印刷一時停止',
    qd: '開始',
    zzdy: '印刷中止',
    dy: '印刷',
    wdy: '未印刷',
    qrsfksdy: '印刷を開始してもよろしいですか？ 開始する前に前回の印刷が完了したモデルを取り出してください。取り出さない場合、モデルの印刷に影響が出る可能性があります。',
    bdcg: 'バインド成功',
    bdsb: 'バインド失敗',
    ybdcdyj: 'このプリンターはすでにバインドされています',
    ztcg: '一時停止成功',
    ztsb: '一時停止失敗',
    jxcg: '再開成功',
    jxsb: '再開失敗',
    zzcg: '中止成功',
    zzsb: '中止失敗',
    qpwjg01: 'スライスファイルは合計',
    qpwjg02: '個あります',
    sfscgqp: 'このスライスを削除しますか？',
    zwqp: 'スライスがありません。モデルライブラリに移動してスライスを追加できます',
    zwsb: 'デバイスがありません。作業台にデバイスを追加できます',
    sfqrztdy: '現在の印刷を一時停止してもよろしいですか？',
    sfqrzzdy: '現在の印刷を中止してもよろしいですか？',
    cg: '層の厚み',
    XYbc: 'XY補償',
    Zbc: 'Z補償',
    kjc: 'アンチエイリアシング',
    zdsczc: 'サポートの自動生成',
    zdscsyzc: 'すべてのサポートを自動生成',
    xzqp: 'スライスを選択',
  },

  historyTask: {
    breadcrumb_home: 'ホーム',
    breadcrumb_gzt: 'ワークベンチ',
    breadcrumb_lsrw: '履歴タスク',
    breadcrumb_rwxq: 'タスクの詳細',
    rwmc: 'タスク名',
    dyzz: '印刷中止',
    zzsj: '中止時間',
    sjzl: 'データの概要',
    fdmqpwj: 'FDMスライスファイル',
    fen: '部',
    gghqpwj: 'スライスファイル',
    device: 'デバイス',
    tai: '台',
    dyzsj: '印刷総時間',
    fz: '分',
    fdmhcyl: 'FDM消耗材料量',
    gghhcyl: '消耗材料量',
    qpwj: 'スライスファイル',
    qpwj: 'スライスファイル',
    // 印刷履歴
    dyrw: '印刷タスク',
    sbsl: 'デバイス数量',
    qpwjfs: 'スライスファイルのコピー数',
    ddy: '印刷待機',
    jxz: '進行中',
    ywc: '印刷完了',
    yzz: '印刷中止',
    zt: '一時停止',
    dysc: '印刷時間',
    hcsyl: '消耗材料の使用量',
    ck: '表示',
    tjrw: 'タスクの追加',
		zwdyrw: '印刷タスクはまだありません',

    djckxq: '詳細をクリックします',
  },
  vip: {
    hytc: 'メンバーシッププラン',
    yuan: '元',
    month: '月',
    ljkt: 'すぐに購読',
    hyqy: 'メンバー特典',
    hl3Dmx: '大量の3Dモデル',
    yqp: 'クラウドスライス',
    yckzdy: 'リモートコントロール印刷',
    ssjkdy: 'リアルタイム印刷モニタリング',
    ycckj: 'クラウドストレージスペース',
    mxplxz: 'モデルの一括ダウンロード',
    dysphf: '印刷動画の再生',
    yssy: 'タイムラプス',
    jqdytq: 'クラスター印刷特典',
    qwDIYjm: '趣味DIYモデリング',
    fbczsp: 'クリエイティブ動画の投稿',
    kjkrzk: 'スペース拡張割引',
    zskf: '専属カスタマーサポート',
    VIPjm: 'VIPモデル',
    VIPrw: 'VIPタスク',
    dmxqp: '複数モデルのスライス',
    ktsm: '開通説明',
    ktsm_text01: '1. 会員センターで特典内容を確認できます。詳細なメンバーサービス規約については、',
    ktsm_text02: '《メンバーサービス契約》',
    ktsm_text03: 'をご参照ください。\n2. 購入完了後、10分以内に会員サービスのサービス期間がアカウントに反映されます。ネットワークの問題により遅れる場合は、5-10分お待ちいただいた後に再度ご確認ください。それでもまだアカウントに反映されない場合や使用できない場合は、オンラインサポートにお問い合わせください。\n3. 購読サイクルは、月単位、四半期単位、年単位などがあります。具体的なサービス開始日は、お客様が選択したタイプにより異なります。特定のイベントで自動更新サービスが開始された場合、対応する請求日が異なる場合があります。詳細はイベントページの案内に従ってください。',
    gmvip: 'メンバーシップを購入',
    content: 'コンテンツ',
    qy: '特典',
    price: '価格',
    x20xqy: '20の特典をお楽しみいただけます',
    zffs: '支払方法',
    yfje: '支払い総額',
    ktvipcg: 'おめでとうございます。メンバーシップの購読が成功しました',
  },
  // 存储空间
  storage: {
    gmkjtc: 'ストレージプランを購入',
    yuan: '元',
    yj: '通常価格',
    qxzkjtc: 'ストレージプランを選択してください',
    yxq01: '有効期限',
    yxq02: '月',
  },
  pay: {
    zffs: '支払方法',
    alipay: 'アリペイ',
    wxpay: '微信ペイ',
    yfje: '支払金額',
    yuan: '元',
    ywczf: '支払い完了',
    nhwwczf: '未払いです',
    syt: 'カウンター',
    ddtjcgcfk: '注文が正常に送信されました、残りはお支払いだけです~',
    ddzfjsts01: 'お支払いは',
    ddzfjsts01: '以内にお願いします、それ以降は自動的にキャンセルされます',
    shxx: 'お届け先情報',
    spmc: '商品名',
    dgd: 'など',
    gmsj: '購入日',
    zffs: '支払方法',
    ddje: '注文金額',
    ddxq: '注文詳細',
    fkcg: '支払い完了',
    zfwchky01: '支払いが完了したら、',
    zfwchky02: '注文詳細を確認できます',
    zfwc: '支払い完了',
    zfts_tips: '現在、外部ウェブサイトで支払いを行っています。支払いが完了したら、このページに戻り、確認してください',
    cxxzzffs: '支払い方法を再選択',
    pay: '支払い',
    wwczfqzf: '未払いです、お支払いください',
    jyygb: '取引はすでに終了しました',
    ckzfqk: '支払い状況を確認',
},
feedback: {
  wdfk: '私のフィードバック',
  fknr: 'フィードバック内容',
  clyj: '処理意見',
  tjsj: '提出時間',
  type: 'タイプ',
},


shoppingCart: {
  gwc: 'ショッピングカート',
  yxsp: '選択された商品',
  bhyf: '送料込み',
  js: '精算',
  spxx: '商品情報',
  sl: '数量',
  je: '金額',
  cz: '操作',
  ydsclb: 'お気に入りに移動',
  qdyscsp: '商品を削除してもよろしいですか？',
  qxzyjssp: '精算する商品を選択してください',
  cgjrgwc: 'カートに追加しました',
  zgg: '他の商品を見る',
  qjs: '精算する',
  sc: 'ショッピングモール',
  ndgwcskd: 'お使いのショッピングカートはまだ空です。早く',
  ggb: '見てみて',
  qxzyscsp: '削除する商品を選択してください',
  qxzyrscjsp: 'お気に入りに移動する商品を選択してください',
  qx: 'すべて選択',
},



  footer: {
    gywm: '弊社について',
    gsjs: '会社概要',
    jrwm: '採用情報',
    zcfw: 'サポートサービス',
    bzzx: 'ヘルプセンター',
    xszn: '初心者ガイド',
    yjfk: 'フィードバック',
    fwxy: 'サービス声明',
    yhxy: '利用規約',
    yszc: 'プライバシーポリシー',
    bqsm: '著作権に関する声明',
    hyfwxy: '会員サービス契約',
    gsjj: '会社概要',
    yxfw_cjmh: '心を込めたサービス・美しい瞬間を演出する',
    feedback_tips01: 'よくある問題や最新のフィードバックを確認するには、',
    feedback_tips02: '「ヘルプセンター」',
    feedback_tips03: 'を訪れてください。',
    fklx: 'フィードバックの種類',
    content: '内容',
    qsrfknr: 'フィードバック内容を入力してください',
    zsxz: '10〜500文字の制限',
    tjfk: 'フィードバックを送信',
    wdfk: '私のフィードバック',
    qxzfklx: 'フィードバックの種類を選択してください',
    qsr10gzfnr: '少なくとも10文字以上のフィードバック内容を入力してください',
    ckgd: 'もっと見る',
  },
}

export default ja
