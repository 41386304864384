<template>
  <div id="app">
    <!--header -->
    <headerView v-show="showHeaderFooter"></headerView>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>-->
    <keep-alive :include="includeRouter"> 
      <router-view />
    </keep-alive>
    <!-- <router-view /> -->

    <!--footer -->
    <footerView v-show="showHeaderFooter"></footerView>
  </div>
</template>
<script>
import headerView from "./components/header";
import footerView from "./components/footer";

import { mapState, mapActions } from 'vuex'
export default {
  name: "App",
  components: {
    headerView,
    footerView,
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      secretKey: (state) => state.secretKey,
      userInfo: (state) => state.userInfoModule.userInfo,
      isInitSocket: (state) => state.webSocketState.isInitSocket, // 是否初始化 socket, 传入 false 也可以关闭
      socketSendMsg: (state) => state.webSocketState.socketSendMsg, // 发送 socket 消息
    })
  },
  watch: {
    $route(to, form) {
      if (to.path == '/agreementPrivacy' || to.path == '/h5/download_app' || to.path == '/slicePreview') {
        this.showHeaderFooter = false;
      } else {
        this.showHeaderFooter = true;
      }
    },
    isInitSocket(msg) {
      if (msg){
        this.initWebSocket(); 
      } else {
        if (!!this.socket) {
          this.socket.close();
          this.socket = null;
          this.socketNetwork = false;
        }
      }
    },
    socketSendMsg(msg) {
      if (!!msg) this.websocketsend(msg);
    },
  },
  data() {
    return {
      lockReconnect: true, // 连接失败进行无限重连
			maxReconnect: 10, // 最大重连次数，若连接失败
			socket: null,
      socketNetwork: false,

      socketTimer: null,

      showHeaderFooter: true,

      includeRouter: 'ModelLib',

      reconnectTimer: null,
    };
  },
  created() {
    this.getDict({dictType: 'zyy_model_class'});
  },
  mounted() {
    document.title = "Octopus Core";
    // this.$nextTick(() => {
    //   let c_vuex = Cookies.get('vuex');
    //   let _vuex = sessionStorage.getItem('vuex');
    //   let _vuexObj = JSON.parse(_vuex);
    //   if (!c_vuex) Cookies.set('vuex', _vuex.toString());
    //   if (!_vuexObj.token && !!c_vuex) sessionStorage.setItem('vuex', c_vuex.toString());
    // });
    
  },
  destroyed() {
    // console.log('vue destroyed!');
		if (!!this.socket) this.socket.close() //离开路由之后断开websocket连接
	},
  methods: {
    ...mapActions({
      getDict: 'dict/getDict',
      initSocket: "webSocketState/initSocket", // 初始化 websocket
      socketReceiveMsg: 'webSocketState/socketReceiveMsg', // socket 接收的消息
      bindDeviceMsg: 'webSocketState/bindDeviceMsg', // 设备绑定成功消息
      pausePrintMsg: 'webSocketState/pausePrintMsg', // 设备暂停成功消息
      continuePrintMsg: 'webSocketState/continuePrintMsg', // 设备继续打印成功消息
      cancelPrintMsg: 'webSocketState/cancelPrintMsg', // 设备继续打印成功消息
      sendSocketMsg: "webSocketState/sendSocketMsg", // 发送的消息
      setSecretKey: "setSecretKey", // 发送的消息
    }),
    initWebSocket() {
			try {
				if ('WebSocket' in window) {
          try {
            // console.log('this.secretKey',this.secretKey);
            if (!this.socket) this.socket = new WebSocket(`${this.apiWebSocketUrl}/websocket/message/${this.secretKey}`)
          } catch(error) {
            this.socket = null;
          }
				} else {
					// console.log('您的浏览器不支持websocket')
				}
				this.socket.onopen = this.websocketonopen
				this.socket.onmessage = this.websocketonmessage
				this.socket.onclose = this.websocketclose
				this.socket.onerror = this.websocketonerror
			} catch (error) {
				// console.error('WebSocket:', error)
			}
		},
    websocketonopen() {
			// console.log('WebSocket连接成功', this.socket.readyState)
			// this.websocketsend();
      this.socketNetwork = true;
      
      clearInterval(this.reconnectTimer);
      this.reconnectTimer = null;

      
      clearInterval(this.socketTimer);
      this.socketTimer = null;
      this.socketTimer = setInterval(() => {
        if (!this.userInfo) return;
        // console.log('this.userInfo.uniqueCode',this.userInfo.uniqueCode);
        let params = {"connectType": 1, "socketType": "beat", "uniqueCode": this.userInfo.uniqueCode + 'pc'};
        // console.log('链接状态》》》》', this.socket.readyState, WebSocket.OPEN);
        if (!!this.socket && this.socket.readyState == WebSocket.OPEN) {
          this.socket.send(JSON.stringify(params));
          // console.log("发送心跳========", params);
        } else {
          this.initSocket(false);
          clearInterval(this.socketTimer);
          this.socketTimer = null;
          this.socket = null;
          this.socketNetwork = false;
        }
      }, 5000);
		},
		websocketsend(msg) {
      // 发送消息
      // console.log("发送消息========", msg);
      // console.log('this.socket',this.socket);
			this.socket.send(msg)
      this.sendSocketMsg('');
		},
		websocketonmessage(e) {
			try {
        if (e.data.indexOf('连接请求已过期') != -1) {
          this.updateSocketSecret();
          return;
        }
				const data = JSON.parse(e.data)
				// console.log('WebSocket返回数据 >>> data', data)

        // TODO: 绑定设备成功 / 失败
        if (!!data && (data.socketType == 'bindSuccess' || data.socketType == 'bindFailed' || data.socketType == 'bindingExist')) {
          this.bindDeviceMsg(data);
        }

        // TODO: 暂停打印成功 / 失败
        if (!!data && (data.socketType == 'printPauseSuccess' || data.socketType == 'printPauseFailed')) {
          this.pausePrintMsg(data);
        }

        // TODO: 继续打印成功 / 失败
        if (!!data && (data.socketType == 'printContinueSuccess' || data.socketType == 'printContinueFailed')) {
          this.continuePrintMsg(data);
        }

        // TODO: 终止打印成功 / 失败
        if (!!data && (data.socketType == 'printCancelSuccess' || data.socketType == 'printCancelFailed')) {
          this.cancelPrintMsg(data);
        }
			} catch (e) {
				// console.error('e: ', e)
			}
		},
		websocketclose(e) {
			// console.log('连接关闭 (' + e.code + ')')

      if (!!this.socket) this.socket.close();
      this.initSocket(false);
			// this.reconnect()
		},
		websocketonerror(e) {
			// console.log('WebSocket连接发生错误', e)
      this.initSocket(false);
			this.reconnect()
		},
		reconnect() {
      if (!this.token) return;
			// console.log('尝试重连', !this.lockReconnect && this.maxReconnect <= 0, !this.lockReconnect)

			if (!this.lockReconnect && this.maxReconnect <= 0) return
      clearInterval(this.reconnectTimer);
      this.reconnectTimer = null;
			this.reconnectTimer = setInterval(() => {
				if (!this.lockReconnect) this.maxReconnect-- // 限制重连次数， 不做限制 连不上一直重连
				this.initWebSocket();

        if (this.socketNetwork) {
          clearInterval(this.reconnectTimer);
          this.reconnectTimer = null;
        }
			}, 5 * 1000)
		},
    // 更新socket秘钥
    updateSocketSecret() {
      let params = {};
      this.http(this.api.getNewSocketKey, params, "get").then((res) => {
        if (res.code == 200) {
          this.setSecretKey(res.data);
          setTimeout(() => {
            this.initSocket(true);
          }, 100);
        } else { 
          // this.$message.error(res.msg);
        }
      });
    }
  }
};
</script>
<style lang="less">
  .el-dropdown-menu {
  z-index: 99999 !important;
}
#app {
  font-family: "PingFang SC";
}
#app_page {
  min-height: calc(100vh - 180px);
}

.el-avatar > img {
  width: 100%;
}

.el-table__body-wrapper {
  z-index: 1;
}
</style>
